import {
  Box,
  Grid,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import pro1 from "../../../assets/Products/Tayewo.svg";
import pro2 from "../../../assets/Products/Ulego.svg";
import pro3 from "../../../assets/Products/adlory.svg";
import pro4 from "../../../assets/Products/pis.png";
import pro5 from "../../../assets/Products/school.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../../App.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const GetStarted = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("1070"));
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const settings = {
    dots: false,
    Infinite: true,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  return (
    <>
      <Box
        sx={{
          height: { lg: "400px", md: "auto", sm: "auto", xs: "auto" },
          mt: 3,
        }}
      >
        <Box
          data-aos="fade-up"
          sx={{
            margin: "0 auto",
            width: { lg: "85%", md: "90%", xs: "90%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: { lg: "400px", md: "auto", sm: "auto", xs: "auto" },
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "outfit",
              color: "#444444",
              fontSize: "22px",
              fontWeight: 200,
            }}
          >
            Our team has built these products:
          </Typography>

          {isMobile ? (
            <Box
              sx={{ margin: "0 auto", width: "90%", height: "100px", mt: 3 }}
            >
              <Slider {...settings}>
                <div className="slider-div">
                  <img src={pro2} width={100} alt="ulego" />
                </div>
                <Box className="slider-div">
                  <img src={pro3} width={100} alt="adlory" />
                </Box>
                <Box className="slider-div">
                  <img src={pro5} width={100} alt="lasmab" />
                </Box>
                <Box className="slider-div">
                  <img src={pro1} width={100} alt="Tayewo" />
                </Box>
                <Box className="slider-div">
                  <img src={pro4} width={100} alt="pis" />
                </Box>
              </Slider>
            </Box>
          ) : (
            <Box
              className="product"
              sx={{
                width: "100%",
                display: "flex",
                mt: 10,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <img src={pro2} width={90} alt="ulego" />
              </Box>
              <Box>
                <img src={pro3} width={90} alt="adlory" />
              </Box>
              <Box>
                <img src={pro5} width={80} alt="lasmab" />
              </Box>
              <Box>
                <img src={pro1} width={90} alt="Tayewo" />
              </Box>
              <Box>
                <img src={pro4} width={90} alt="pis" />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default GetStarted;
