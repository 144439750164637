import React from "react";
import Navbar from "../Components/Head/Navbar";
import { Avatar, Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import img1 from "../assets/CodeCamp/1.svg";
import img2 from "../assets/CodeCamp/2.svg";
import img3 from "../assets/CodeCamp/3.svg";
import img4 from "../assets/CodeCamp/4.svg";
import img5 from "../assets/CodeCamp/5.svg";
import img6 from "../assets/CodeCamp/6.svg";
import img7 from "../assets/CodeCamp/7.svg";
import img8 from "../assets/CodeCamp/8.svg";
import img9 from "../assets/CodeCamp/9.svg";
import img10 from "../assets/CodeCamp/10.svg";
import img11 from "../assets/CodeCamp/11.svg";
import img12 from "../assets/CodeCamp/12.svg";
import img13 from "../assets/CodeCamp/13.svg";
import img14 from "../assets/CodeCamp/14.svg";
import img15 from "../assets/CodeCamp/technical writing.jpg";
import img16 from "../assets/CodeCamp/wordpress.webp";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import Footer from "../Components/Footer/Footer";
import { Helmet } from "react-helmet";
import ReactCountryFlag from "react-country-flag";

const courseData = [
  // {
  //   img:img1,
  //   course:'Full Stack Development',
  //   duration:'6 months course',
  //   description:'HTML & CSS, Vanilla JavaScript, React JS, NodeJS, Express Framework',
  // },
  {
    img: img2,
    course: "Data Science and Machine Learning",
    duration: "4 months course",
    description:
      "Python,Numpy, Pandas, Sci-kit Learn, Jupyter notebook,Matplotlib, Tensorflow,Data Visualization, deep learning, neural networks, etc.",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdHeadxWs7woZp2b1jyF-rEfQvovzXMKwZ4atpbr9ds-I16qA/viewform?usp=sf_link",
  },
  {
    img: img3,
    course: "Front End Development ",
    duration: "4 months course",
    description: "HTML, CSS, BOOTSTRAP, JAVASCRIPT AND GIT ReactJS and VueJS.",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdHeadxWs7woZp2b1jyF-rEfQvovzXMKwZ4atpbr9ds-I16qA/viewform?usp=sf_link",
  },
  {
    img: img1,
    course: "Full Stack Development",
    duration: "5 months course",
    description:
      "HTML & CSS, Vanilla JavaScript, React JS, NodeJS, Express Framework",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdHeadxWs7woZp2b1jyF-rEfQvovzXMKwZ4atpbr9ds-I16qA/viewform?usp=sf_link",
  },
  {
    img: img4,
    course: "Back End / API Development ",
    duration: "4 months course",
    description:
      "HTML & CSS, Vanilla JavaScript, React JS, NodeJS, Express Framework",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdHeadxWs7woZp2b1jyF-rEfQvovzXMKwZ4atpbr9ds-I16qA/viewform?usp=sf_link",
  },
  {
    img: img5,
    course: "UIUX Design",
    duration: "4 months course",
    description:
      "Figma,Grids, Components, Design Systems,User Flow, Sketches,Inspirations Wireframes etc. ",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdHeadxWs7woZp2b1jyF-rEfQvovzXMKwZ4atpbr9ds-I16qA/viewform?usp=sf_link",
  },
  {
    img: img6,
    course: "Blockchain Development    ",
    duration: "5 months course",
    description:
      "HTML & CSS, Vanilla JavaScript, React JS, NodeJS, Express Framework",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdHeadxWs7woZp2b1jyF-rEfQvovzXMKwZ4atpbr9ds-I16qA/viewform?usp=sf_link",
  },
  {
    img: img7,
    course: "Data Science and Analytics    ",
    duration: "4 months course",
    description: "Excel, SQL, Statistic, Python,Numpy, Pandas, PowerBI.",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdHeadxWs7woZp2b1jyF-rEfQvovzXMKwZ4atpbr9ds-I16qA/viewform?usp=sf_link",
  },
  {
    img: img8,
    course: "Mobile App Development (Flutter)    ",
    duration: "5 months course",
    description:
      "HTML & CSS, Vanilla JavaScript, React JS, NodeJS, Express Framework",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdHeadxWs7woZp2b1jyF-rEfQvovzXMKwZ4atpbr9ds-I16qA/viewform?usp=sf_link",
  },
  {
    img: img9,
    course: "Software Testing",
    duration: "4 months course",
    description:
      "SDLC,Software Development Methodology, V Model Agile Methodology, API testing, jira,postman,selenium, mobile testing, web testing, appium,automation testing etc. ",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdHeadxWs7woZp2b1jyF-rEfQvovzXMKwZ4atpbr9ds-I16qA/viewform?usp=sf_link",
  },
  {
    img: img10,
    course: "Cyber Security",
    duration: "4 months course",
    description:
      "HTML,CSS, PYTHON,Security Principles,Security operations,Scripting in cybersecurity etc.",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdHeadxWs7woZp2b1jyF-rEfQvovzXMKwZ4atpbr9ds-I16qA/viewform?usp=sf_link",
  },

  {
    img: img12,
    course: "Mobile App Development (React Native)  ",
    duration: "4 months course",
    description:
      "HTML & CSS, Vanilla JavaScript, React JS, NodeJS, Express Framework",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdHeadxWs7woZp2b1jyF-rEfQvovzXMKwZ4atpbr9ds-I16qA/viewform?usp=sf_link",
  },
  {
    img: img13,
    course: "Project Management",
    duration: "4 months course",
    description:
      "Product Development Product, Development Process Product Management Frameworks,Market Analysis ,Scrum, jira etc. ",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdHeadxWs7woZp2b1jyF-rEfQvovzXMKwZ4atpbr9ds-I16qA/viewform?usp=sf_link",
  },
  {
    img: img15,
    course: "Technical Writing",
    duration: "4 months course",
    description:
      "Clear and Concise Writing,Research,Organization and Structure,Audience Analysis,Visual Communication, etc.",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdHeadxWs7woZp2b1jyF-rEfQvovzXMKwZ4atpbr9ds-I16qA/viewform?usp=sf_link",
  },
  {
    img: img16,
    course: "Word Press Development",
    duration: "3 months course",
    description: "HTML, CSS, Wordpress, Elementor etc.",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdHeadxWs7woZp2b1jyF-rEfQvovzXMKwZ4atpbr9ds-I16qA/viewform?usp=sf_link",
  },
];
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "linear-gradient(270deg, #00A5EC 0%, #02265C 123.61%)",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 13,
    fontFamily: "outfit",
    padding: 13,
  },
}));
const UkCohorts = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> UK Cohorts | Nupat Technologies </title>
        <link rel="canonical" href="https://nupat.africa/" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-2HLS2GJ4LF"
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-2HLS2GJ4LF');
          `}
        </script>
      </Helmet>
      <Navbar />
      <Box
        className="code_camp_uk bg-center bg-no-repeat"
        sx={{ height: { lg: "400px", md: "400px", xs: "400px" } }}
      >
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "60%", md: "60%", xs: "90%" },
            pt: { lg: 19, md: 19, xs: 14 },
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              fontFamily: "outfit",
              fontWeight: 600,
              textAlign: "center",
              fontSize: { lg: "40px", md: "40px", xs: "30px" },
            }}
          >
            NUPAT Code Camp - UK Cohorts{" "}
            <span>
              <ReactCountryFlag countryCode="GB" />
            </span>
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              fontFamily: "outfit",
              fontWeight: 200,
              textAlign: "center",
              fontSize: { lg: "16px", md: "16px", xs: "14px" },
              lineHeight: { lg: "30px", md: "30px", xs: "23px" },
            }}
          >
            Nupat Code Camp is an immersive and rigorous training program
            designed to cultivate a new wave of indigenous tech professionals
            who are actively propelling innovation and advancement within the
            information and technology industry. Leveraging the vast expertise
            and skill sets of seasoned industry veterans, the program offers
            top-tier practical training and comprehensive online resources.
          </Typography>
        </Box>
      </Box>

      <Box sx={{ margin: "0 auto", width: "85%" }}>
        <Box sx={{ borderLeft: "7px solid #4bbeff", pl: 2, mt: 5 }}>
          <Typography
            sx={{
              fontFamily: "outfit",
              fontWeight: 500,
              color: "#484848",
              fontSize: "30px",
            }}
          >
            Guaranteed way to start your Tech career
          </Typography>
        </Box>
        <Typography
          sx={{
            mt: 4,
            fontFamily: "outfit",
            fontWeight: 200,
            color: "#484848",
          }}
        >
          The program is designed to provide you with the technical skills and
          knowledge you need to succeed in today's fast-paced tech industry.
          <br />
          <br />
          Our comprehensive program covers a range of topics, from technical
          skills to soft skills, including programming languages, software
          development, data analysis, etc and is taught by industry experts with
          years of experience in their respective fields. You'll learn from
          industry experts with years of experience, and gain hands-on
          experience through real-world projects and assignments.
          <br />
          <br />
          We understand that flexibility is key, which is why we offer both
          in-person and online training options to fit your schedule and
          learning preferences. The program is also customizable, allowing you
          to focus on the areas that matter most to your career goals.
          <br />
          <br />
          Upon completion of the program, you'll receive a certificate that
          demonstrates your expertise and commitment to professional
          development. This will not only help you stand out to potential
          employers, but also give you the confidence to pursue your career
          goals with conviction.
        </Typography>
      </Box>

      <Box sx={{ margin: "0 auto", width: "80%", my: 10 }}>
        <Grid container spacing={3}>
          {courseData.map((row, index) => (
            <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
              <Box
                sx={{
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Avatar
                  src={row.img}
                  variant="rounded"
                  sx={{ width: "100%", height: "100px" }}
                />
                <Box sx={{ p: 2, height: "170px" }}>
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 500,
                      fontSize: "18px",
                    }}
                  >
                    {row.course}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      fontSize: "13px",
                      mt: 2,
                    }}
                  >
                    <AccessTimeIcon sx={{ mr: 1, fontSize: "15px" }} />{" "}
                    {row.duration}
                  </Typography>
                  {/* <Typography sx={{fontFamily:'outfit', fontWeight:200, fontSize:'13px', mt:1}}>&#8358; 350,000</Typography> */}

                  <LightTooltip title={row.description} placement="top-start">
                    <Typography
                      sx={{
                        fontFamily: "outfit",
                        fontWeight: 200,
                        fontSize: "13px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Inventory2Icon sx={{ mr: 1, fontSize: "15px", mt: 1 }} />{" "}
                      {row.description}
                    </Typography>
                  </LightTooltip>
                </Box>
                <Box
                  sx={{
                    bgcolor: "#F3F8FF",
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "outfit",
                        fontWeight: 200,
                        fontSize: "11px",
                      }}
                    >
                      Next Cohort Starts
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "outfit",
                        fontWeight: 300,
                        fontSize: "13px",
                      }}
                    >
                      1st of February, 2024
                    </Typography>
                  </Box>
                  <Box>
                    <a href={row.link} target="_blank">
                      <Button
                        sx={{
                          background:
                            "linear-gradient(270deg, #00A5EC 0%, #02265C 123.61%)",
                          color: "#fff",
                          fontFamily: "outfit",
                          textTransform: "initial",
                          fontWeight: 200,
                          px: 2,
                        }}
                      >
                        Enroll Now{" "}
                      </Button>
                    </a>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default UkCohorts;
