import React, { useState, useEffect } from 'react';
import Whatsapp from '../../assets/Whatsapp.png'
import { IoMdClose } from 'react-icons/io';
import './whatsapp.css'
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

const WhatsApp = () => {
   const name = 'Patrick'

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="relative">
      <img
        src={Whatsapp}
        alt="WhatsApp"
        className="h-[50px] w-[50px] float-image cursor-pointer"
        onClick={handleImageClick}
      />

      {isModalOpen && (
        <div
          className="fixed right-[-13px] bottom-[4rem] lg:right-[25px] p-9 z-1000"
          data-aos="fade-up-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <div className="shadow-lg text-left w-[300px] lg:w-[350px] h-[200px]">
            <div className="bg-green-600 rounded-tl-lg rounded-tr-lg">
              <h2 className="text-[12px] lg:text-[15px] text-white font-semibold pb-[30px] py-[25px] pt-[15px] pl-[14px] font-[Montserrat]">
                Click here to chat with one of our{' '}
                <span className="block">representatives.</span>
              </h2>
            </div>
           
           <>
           <div className='bg-white h-[130px]'>
            <a
              href="https://wa.me/+2348031958586"
              target="_blank"
              rel="noopener noreferrer"
              className=''
            >
              <div className="flex gap-[10px] items-center py-[20px] pl-[14px] relative top-[22px] mx-3 hover:bg-gray-100 rounded-lg">
                <img src={Whatsapp} alt="" className="h-[50px] w-[50px]" />
                <div>
                  <p className="text-[15px] text-gray-400 font-[Montserrat]">Support</p>

                  <p className="text-[16px] font-[600] font-[Montserrat]">{name}</p>
                </div>
              </div>
            </a>
            </div>
            </>
            <button
              className="text-gray-100 fixed bottom-[11.9rem] right-[30px] px-4 py-2 rounded"
              onClick={closeModal}
            >
              <IoMdClose size={25} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WhatsApp;
