import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { findCertificateByCode } from "../Routes";
import { Button } from "@mui/material";
import oguns from "../assets/OGUNJOBI.jpg";
// import Cert from '../../src/Routes'
export const Certificates = ({ certificates }) => {
  const { code } = useParams();
  const [certificate, setCertificate] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchCertificate = async () => {
      const certificateData = await findCertificateByCode(code);
      setCertificate(certificateData);
    };

    fetchCertificate();
  }, [code]);

  return (
    <div className="certContainer ">

{/* <div>
{Cert.map}
</div> */}

      {certificate ? (
        <div className=" pb-[50px]">
          <div className="wrap">
            <div className="welcomediv">
              {/* <p className="welcome">Certificate for {certificate.code}</p> */}
              <p className="text-center text-gray-500 text-[30px] font-[700] font-[Monserrat]"> Certificate for {certificate.name}</p>
            </div>
            <div className="imgWrap">
              <img
                className="cert"
                src={certificate.image}
                alt={`Certificate for code: ${certificate.code}`}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="circle">
          <p className="welcome">wait while your certificate is loading..</p>
        </div>
      )}
      <div className="flex justify-center">
        <div className="pb-[40px] flex justify-between md:w-[600px] w-full px-[10px] md:px-0">
          <Button
            onClick={() => navigate("/")}
            className="lineUp"
            sx={{
              background:
                "linear-gradient(270.4deg, #131e47 17.31%, #338695 99.58%)",
              color: "#fff",
              fontFamily: "Sans",
              px: 5,
              borderRadius: "0px",
            }}
          >
            Visit Nupat Website
          </Button>
          <a href="https://nupatinitiatives.org/">
            <Button
              className="lineUp"
              sx={{
                background:
                  "linear-gradient(270.4deg, #131e47 17.31%, #338695 99.58%)",
                color: "#fff",
                fontFamily: "Sans",
                px: 5,
                borderRadius: "0px",
              }}
            >
              Visit Nupat Initiative
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};
