import "./App.css";
import ModalForm from "./Components/modalform/ModalForm";
import WhatsApp from "./Components/whatsapp/WhatsApp";
import ScrollToTop from "./Pages/ScrollToTop";
import { Routes } from "./Routes";
import "./index.css";
function App() {
  return (
    <>
      <ScrollToTop />
      <Routes />
      <div>
        <ModalForm/>
        <WhatsApp/>
      </div>

    </>
  );
}

export default App;
