import React, { useEffect, useState } from "react";

// MUI Component
import { Box, Grid, Typography, useTheme, useMediaQuery, Button } from "@mui/material";

// Component
import Navbar from "./Navbar";


// CSS
import "../../App.css";
import { Link, useNavigate } from "react-router-dom";

// Images



const Banner = () => {
  const theme = useTheme();
  const [show, setShow] = useState(false)
  const navigate=useNavigate()

//   useEffect(()=>{
// setShow(false)
// if (!show){
//   setTimeout(()=>{
// setShow(true)
//   },2500)
// }
//   },[])
  const isMobile = useMediaQuery(theme.breakpoints.down("1070"));
  const handleClick =()=>{
navigate('/about-us')
  }
  const handleGet =()=>{
navigate('/services')
  }
  
  return (
    <>
      <Box
        sx={{
          height: { lg: "887px", md: "887px", sm: "auto", xs: "auto" },
          // bgcolor: "#000E06",
          overflow: "hidden",
        }}
        id="home"
        className="banner"
      >
        <Navbar />
        <Box
          className="banner_container"
          sx={{ height: { lg: "885px", md: "885px", sm: "auto", xs: "auto" } }}
        >
          <Box
            sx={{
              height: { lg: "887px", md: "887px", sm: "auto", xs: "auto" },
            }}
            className="back"
          >
            <Box pb={10} sx={{pt:{lg:27, md:27, xs:13}}}>
          
        
            <Typography sx={{fontSize:{lg:'100px', md:'100px', xs:'60px'}, fontWeight:700, color:'#fff', textAlign:'center', fontFamily:'outfit', lineHeight:{lg:'121px', md:'121px', xs:'70px'}, transition:'0.5s all ease-in'}}>Building Innovations, Empowering Change </Typography>
            <Box sx={{width:{lg:'60%',md:'60%', xs:'90%'}, margin:'0 auto'}}>
            <Typography className="lineUp" sx={{fontSize:'18px', fontWeight:200, color:'#fff', textAlign:'center', fontFamily:'outfit',mt:2 }}>Join our vibrant community of tech enthusiasts, innovators, and entrepreneurs. We are raising world-class tech professionals, building problem-solving innovations, and helping you bring your dreams to reality. </Typography>

            <Box align="center" className="line"  sx={{mt:{lg:12, md:12, xs:8}, display:'flex', justifyContent:'center'}}>



              <Button onClick={handleGet} className="lineUp" sx={{background:' linear-gradient(90deg, #F89103 2.36%, #F9C41E 100%)', color:'#000', fontFamily:'Sans', px:5, borderRadius:'0px', }}>Get Started</Button>
      


              <Button onClick={handleClick} className="lineUp" sx={{background:' #fff', color:'#000', fontFamily:'Sans', px:5, ml:3, borderRadius:'0px', '&:hover':{background:'#00000080', color:'#fff'} }}>Learn More</Button>
            
            </Box>
            </Box>
          
            </Box>
          </Box>
        </Box>
      </Box>
    
    </>
  );
};

export default Banner;
