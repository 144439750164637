import React from "react";

// MUI Component
import {
  Box,
  Grid,
  Typography,
  Stack,
  IconButton,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";

// Images
import logo from "../../assets/Nupat transparent Allwhite-.png";
import twitter from "../../assets/icons/twitter.svg";
import facebook from "../../assets/icons/facebook.svg";
import instagram from "../../assets/icons/instagram.svg";
import linkedIn from "../../assets/icons/linked.svg";
import { Link } from "react-router-dom";
import { HashLink as Links } from 'react-router-hash-link'




const Footer = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("900"));


  return (
    <>
      <Box
        className="footer"
        sx={{
          background:
            "linear-gradient(270.4deg, #131E47 17.31%, #338695 99.58%)",
          pb: 4,
        }}
      >
        <Box
          sx={{
            margin: "0 auto",
            width: "90%",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={3} mt={5}>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Box align="left" sx={{ ml: { lg: 0, md: 0, xs: -9 } }}>
                <img src={logo} width={200} alt="Digcey_Logo" />
              </Box>
            </Grid>
            <Grid
              item
              lg={5}
              md={6}
              sm={12}
              xs={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box align="left">
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 500,
                    color: "#00D8FF",
                    fontSize: "16px",
                  }}
                >
                  Our Services
                </Typography>
                <Link to="/code-camp">
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#FFF",
                      fontSize: "13px",
                      mt: 3,
                      '&:hover': {
                        fontWeight: 500,
                        borderBottom: '1px solid #fff'
                      }
                    }}
                  >
                    NUPAT Code Camp
                  </Typography>
                </Link>
                <Link to="/it-services">
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#FFF",
                      fontSize: "13px",
                      mt: 2,
                      '&:hover': {
                        fontWeight: 500,
                        borderBottom: '1px solid #fff',
                        width: 'fit-content'
                      }
                    }}
                  >
                    IT Services
                  </Typography>
                </Link>
                <Link to="/">
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#FFF",
                      fontSize: "13px",
                      mt: 2,
                      '&:hover': {
                        fontWeight: 500,
                        borderBottom: '1px solid #fff',
                        width: 'fit-content'
                      }
                    }}
                  >
                    Talent Outsourcing
                  </Typography>
                </Link>
                <Link to="/code-camp">
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#FFF",
                      fontSize: "13px",
                      mt: 2,
                      '&:hover': {
                        fontWeight: 500,
                        borderBottom: '1px solid #fff',
                        width: 'fit-content'
                      }
                    }}
                  >
                    Trainings
                  </Typography>
                </Link>
                <Links to="/services#contacts" spy={true} smooth={true} offset={-100} duration={500}>
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#FFF",
                      fontSize: "13px",
                      mt: 2,
                      '&:hover': {
                        fontWeight: 500,
                        borderBottom: '1px solid #fff',
                        width: 'fit-content'
                      }
                    }}
                  >
                    Contacts
                  </Typography>
                </Links>

              </Box>
              <Box align="left">
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 500,
                    color: "#00D8FF",
                    fontSize: "16px",
                  }}
                >
                  About Us
                </Typography>
                <Link to="/about-us">
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#FFF",
                      fontSize: "13px",
                      mt: 3,
                      '&:hover': {
                        fontWeight: 500,
                        borderBottom: '1px solid #fff',
                        width: 'fit-content'
                      }
                    }}
                  >
                    History
                  </Typography>
                </Link>
                <Link to="/">
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#FFF",
                      fontSize: "13px",
                      mt: 2,
                      '&:hover': {
                        fontWeight: 500,
                        borderBottom: '1px solid #fff',
                        width: 'fit-content'
                      }
                    }}
                  >
                    Our Mission
                  </Typography>
                </Link>
                <Link to="/">
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#FFF",
                      fontSize: "13px",
                      mt: 2,
                      '&:hover': {
                        fontWeight: 500,
                        borderBottom: '1px solid #fff',
                        width: 'fit-content'
                      }
                    }}
                  >
                    Vision Statement
                  </Typography>
                </Link>
                <Link to="/">
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#FFF",
                      fontSize: "13px",
                      mt: 2,
                      '&:hover': {
                        fontWeight: 500,
                        borderBottom: '1px solid #fff',
                        width: 'fit-content'
                      }
                    }}
                  >
                    Core Values
                  </Typography>
                </Link>
                <Link to="/">
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#FFF",
                      fontSize: "13px",
                      mt: 2,
                      '&:hover': {
                        fontWeight: 500,
                        borderBottom: '1px solid #fff',
                        width: 'fit-content'
                      }
                    }}
                  >
                    Clients
                  </Typography>
                </Link>

              </Box>
              <Box align="left">
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 500,
                    color: "#00D8FF",
                    fontSize: "16px",
                  }}
                >
                  NUPAT Initiatives
                </Typography>
                <a href="https://nupatinitiatives.org" target="_blank" >
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#FFF",
                      fontSize: "13px",
                      mt: 3,
                      '&:hover': {
                        fontWeight: 500,
                        borderBottom: '1px solid #fff',
                        width: 'fit-content'
                      }
                    }}
                  >
                    History
                  </Typography>
                </a>
                <a href="https://nupatinitiatives.org">
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#FFF",
                      fontSize: "13px",
                      mt: 2,
                      '&:hover': {
                        fontWeight: 500,
                        borderBottom: '1px solid #fff',
                        width: 'fit-content'
                      }
                    }}
                  >
                    Social Responsibility
                  </Typography>
                </a>
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 200,
                    color: "#FFF",
                    fontSize: "13px",
                    mt: 2,
                    '&:hover': {
                      fontWeight: 500,
                      borderBottom: '1px solid #fff',
                      width: 'fit-content'
                    }
                  }}
                >
                  Careers
                </Typography>
                <a href="https://nupatinitiatives.org">
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#FFF",
                      fontSize: "13px",
                      mt: 2,
                      '&:hover': {
                        fontWeight: 500,
                        borderBottom: '1px solid #fff',
                        width: 'fit-content'
                      }
                    }}
                  >
                    Blog
                  </Typography>
                </a>
              </Box>
            </Grid>
            <Grid item lg={2.5} md={3} sm={12} xs={12}>
              <Box sx={{ ml: { lg: 3, md: 3, xs: 0 } }}>
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 500,
                    color: "#00D8FF",
                    fontSize: "16px",
                  }}
                >
                  Contact us
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 200,
                    color: "#FFF",
                    fontSize: "13px",
                    mt: 3,
                  }}
                >
                  46 Community road, Akoka, Lagos, Nigeria
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 200,
                    color: "#FFF",
                    fontSize: "13px",
                    mt: 3,
                    whiteSpace: "nowrap",
                  }}
                >
                  23, Adebisi Oyenola Street,  <span className="block">Peaceville Estate,Idado, Lekki, Lagos</span>
                </Typography>
                <a href="tel: +2348031958586">
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#FFF",
                      fontSize: "13px",
                      mt: 2,
                    }}
                  >
                    (+234) 803 195 8586,
                  </Typography>
                </a>
                <a href="tel: +2347081475750">
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#FFF",
                      fontSize: "13px",
                    }}
                  >
                    (+234) 708 147 5750
                  </Typography>
                </a>
              </Box>
            </Grid>
            <Grid item lg={2.5}>
              <Stack direction="row" spacing={1} align="left">
                <a href="https://web.facebook.com/nupattechnologies/?_rdc=1&_rdr">
                  <IconButton
                    sx={{
                      transition: "0.2s all linear",
                      "&:hover": {
                        border: "0.6px solid #00D8FF",
                        bgcolor: "#ffffff1a",
                      },
                    }}
                  >
                    <img src={facebook} alt="facebook" width={20} />
                  </IconButton>
                </a>
                <a href="https://twitter.com/NupatOfficial?s=09">
                  <IconButton
                    sx={{
                      transition: "0.2s all linear",
                      "&:hover": {
                        border: "0.6px solid #00D8FF",
                        bgcolor: "#ffffff1a",
                      },
                    }}
                  >
                    <img src={twitter} alt="twitter" width={20} />
                  </IconButton>
                </a>
                <a
                  href="https://www.instagram.com/nupat_technologies/"
                >
                  <IconButton
                    sx={{
                      transition: "0.2s all linear",
                      "&:hover": {
                        border: "0.6px solid #00D8FF",
                        bgcolor: "#ffffff1a",
                      },
                    }}
                  >
                    <img src={instagram} alt="instagram" width={20} />
                  </IconButton>
                </a>
                <a
                  href="
                    https://www.linkedin.com/company/nupat-technologies/"
                >
                  <IconButton
                    sx={{
                      transition: "0.2s all linear",
                      "&:hover": {
                        border: "0.6px solid #00D8FF",
                        bgcolor: "#ffffff1a",
                      },
                    }}
                  >
                    <img src={linkedIn} alt="linkedin" width={20} />
                  </IconButton>
                </a>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            width: { lg: "65%", md: "65%", xs: "90%" },
            margin: "0 auto",
            mt: 10,
            display: "flex",
            flexDirection: { lg: "row", md: "row", xs: "column-reverse" },
          }}
        >
          <Grid item lg={6} xs={12}>
            <Typography
              sx={{
                fontFamily: "outfit",
                fontWeight: 200,
                color: "#00D8FF",
                fontSize: "13px",
              }}
            >
              ©{new Date().getFullYear()} NUPAT Technologies, All rights reserved
            </Typography>
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              sx={{
                fontFamily: "outfit",
                fontWeight: 200,
                color: "#fff",
                fontSize: "13px",
              }}
            >
              Privacy Policy
            </Typography>
            <Typography
              sx={{
                fontFamily: "outfit",
                fontWeight: 200,
                color: "#fff",
                fontSize: "13px",
              }}
            >
              Terms & Conditions
            </Typography>
            <Typography
              sx={{
                fontFamily: "outfit",
                fontWeight: 200,
                color: "#fff",
                fontSize: "13px",
              }}
            >
              Cookies Policy
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
