import {
  Box,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Divider,
  Button,
  createTheme,
  ThemeProvider,
  Avatar,
} from "@mui/material";
import React,{useEffect} from "react";
import Aos from 'aos'
import 'aos/dist/aos.css'
import lead1 from "../../../assets/Leaders/Patrick.png";
import lead2 from "../../../assets/Leaders/henry.jpg";
import lead3 from "../../../assets/Leaders/paulo.png";
import lead4 from "../../../assets/Leaders/ibk.jpg";
import badge1 from "../../../assets/icons/mentor.svg";
import badge2 from "../../../assets/icons/community.svg";
import badge3 from "../../../assets/icons/portfolio.svg";
import badge4 from "../../../assets/icons/intern.svg";
import badge5 from "../../../assets/icons/master.svg";
import badge6 from "../../../assets/icons/cert.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Testimonial from "../Testimonial";
import ReactPlayer from 'react-player'
import { Link } from "react-router-dom";

const theming = createTheme({
  palette: {
    primary: {
      main: "#373B3F",
    },
  },
});


const Faq = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("901"));
  useEffect(()=>{
    Aos.init({duration: 1000});
  },[])

  
  return (
    <>
      <Box sx={{ height: "auto", pb: 15, my: 5 }}>
        <Box data-aos="fade-up">
          <Box
            className="hero_middle"
            sx={{
              margin: "0 auto",
              width: { lg: "70%", md: "70%", sm: "90%", xs: "90%" },
              height: {lg:"500px", md:"500px", xs:'300px'},
            }}
          ></Box>
          <Box
            sx={{
              margin: "0 auto",
              width: {lg:"50%", md:"50%", xs:'80%',},
              background:
                "linear-gradient(269.94deg, #3B3838 -28.47%, #244659 132.95%)",
              mt: -15,
              p: 4,
            }}
          >
            <Typography
            data-aos="zoom-in"
              sx={{
                textAlign: "center",
                fontFamily: "outfit",
                fontWeight: 600,
                color: "#00D8FF",
                mb: 2,
              }}
            >
              NUPAT Code Camp
            </Typography>
            <Typography
              sx={{ color: "#fff", fontFamily: "outfit", fontWeight: 200 }}
            >
              Nupat Code camp is an intensive practical software development
              boot-camp that is ushering in a new generation of indigenous
              software developers driving innovation and software development in
              the information and technological industry. The training utilizes
              the experience and skill sets of veterans in the industry. Quality
              practical training and online resources are used in a conducive,
              team building and challenging environment to inspire and mold the
              interns to become great tech experts.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ margin: "0 auto", width:{lg: "60%", md:"60%", xs:'80%'}, mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item lg={3} xs={6}>
              <Box sx={{ borderRight: "0.7px solid #949CA6" }}>
                <Typography
                data-aos="zoom-in"
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 700,
                    color: "#949CA6",
                    fontSize: {lg:"60px", md:'60px', xs:'30px'},
                  }}
                >
                  1000 +
                </Typography>
                <Typography
                    data-aos="fade-up"
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 200,
                    color: "#373B3F",
                    fontSize: {lg:"20px", md:'20px', xs:'16px'},
                  }}
                >
                  Students Trained
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={4} xs={6} >
              <Box align="center" sx={{ borderRight: "0.7px solid #949CA6" }}>
                <Typography
                    data-aos="zoom-in"
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 700,
                    color: "#949CA6",
                    fontSize: {lg:"60px", md:'60px', xs:'30px'},
                  }}
                >
                  15 +
                </Typography>
                <Typography
                    data-aos="fade-up"
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 200,
                    color: "#373B3F",
                    fontSize: {lg:"20px", md:'20px', xs:'16px'},
                  }}
                >
                  Courses
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={3} xs={6}>
              <Box align="center" sx={{}}>
                <Typography
                    data-aos="zoom-in"
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 700,
                    color: "#949CA6",
                    fontSize: {lg:"60px", md:'60px', xs:'30px'},
                  }}
                >
                  55 +
                </Typography>
                <Typography
                    data-aos="fade-up"
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 200,
                    color: "#373B3F",
                    fontSize: {lg:"20px", md:'20px', xs:'16px'},
                  }}
                >
                  Satisfied Clients
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              lg={2}
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ThemeProvider theme={theming}>
                <Link to="/code-camp">
                <Button variant="outlined">Enroll Now</Button>
                </Link>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ margin: "0 auto", width: {lg:"40%", md:'40%', xs:'80%'}, mt: 14 }}>
          <Divider>
            <Typography
              sx={{ fontFamily: "outfit", fontWeight: 600, fontSize: "26px" }}
            >
              WHY NUPAT
            </Typography>
          </Divider>
        </Box>
        <Box sx={{ margin: "0 auto", width:{lg: "85%", md:'85%', xs:'90%'}, mt: 2 }}>
          <Typography
            sx={{
              fontFamily: "outfit",
              fontWeight: 200,
              fontSize: "18px",
              textAlign: "center",
            }}
          >
        We have a variety of courses available at your disposal for either weekday or weekend sessions in order to suit your schedules.
          </Typography>
          <Box>
            <Grid container spacing={3} mt={6}>
              <Grid          data-aos="zoom-in"item lg={4} md={6} sm={6}>
                <Box
                  align="center"
                  sx={{
                    bgcolor: "#fffcfc",
                    height: "400px",
                    boxShadow: " 0px 4px 25px rgba(0, 0, 0, 0.08)",
                    borderRadius: "25px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    px: 4,
                    transition:'.2s all linear','&:hover':{ boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'} 
                  }}
                >
                  <img src={badge1} alt="Mentorship" />
                  <Typography
                    sx={{ fontFamily: "outfit", fontWeight: 600, mt: 2 }}
                  >
                    Mentorship
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      lineHeight: "29px",
                      mt: 2,
                    }}
                  >
                 We provide the best programs structured with diverse and brilliant mentors that put all their efforts and skills into positively influencing, shaping, and reshaping the mindset of interns to prepare them for service.
                  </Typography>
                </Box>
              </Grid>
              <Grid         data-aos="zoom-in" item lg={4} md={6} sm={6}>
                <Box
                  align="center"
                  sx={{
                    bgcolor: "#fcfffb",
                    height: "400px",
                    boxShadow: " 0px 4px 25px rgba(0, 0, 0, 0.08)",
                    borderRadius: "25px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    px: 4,
                    transition:'.2s all linear','&:hover':{ boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'} 
                  }}
                >
                  <img src={badge2} alt="Mentorship" />
                  <Typography
                    sx={{ fontFamily: "outfit", fontWeight: 600, mt: 2 }}
                  >
                    Community
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      lineHeight: "29px",
                      mt: 2,
                    }}
                  >
              Join our vibrant community of tech enthusiasts, innovators, and entrepreneurs. We are raising world-class tech professionals, building problem-solving innovations, and helping you bring your dreams to reality.
                  </Typography>
                </Box>
              </Grid>
              <Grid         data-aos="zoom-in" item lg={4} md={6} sm={6}>
                <Box
                  align="center"
                  sx={{
                    bgcolor: "#fffffd",
                    height: {lg:"400px", md:'400px', xs:'500px'},
                    boxShadow: " 0px 4px 25px rgba(0, 0, 0, 0.08)",
                    borderRadius: "25px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    px: 4,
                    transition:'.2s all linear','&:hover':{ boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'} 
                  }}
                >
                  <img src={badge3} alt="Mentorship" />
                  <Typography
                    sx={{ fontFamily: "outfit", fontWeight: 600, mt: 2 }}
                  >
                    Portfolio
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      lineHeight: "29px",
                      mt: 2,
                    }}
                  >
               During the duration of the internship program, our meticulously crafted curriculum offers you the opportunity to engage in life projects while facilitating internships with esteemed organizations. This invaluable experience not only enhances your skills and capabilities but also contributes to the development of an impressive professional portfolio.
                  </Typography>
                </Box>
              </Grid>
              <Grid     data-aos="fade-up" item lg={4} md={6} sm={6}>
                <Box
                  align="center"
                  sx={{
                    bgcolor: "#fcfdff",
                    height: "400px",
                    boxShadow: " 0px 4px 25px rgba(0, 0, 0, 0.08)",
                    borderRadius: "25px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    px: 4,
                    transition:'.2s all linear','&:hover':{ boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'} 
                  }}
                >
                  <img src={badge4} alt="Mentorship" />
                  <Typography
                    sx={{ fontFamily: "outfit", fontWeight: 600, mt: 2 }}
                  >
                    Internship
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      lineHeight: "29px",
                      mt: 2,
                    }}
                  >
               We provide comprehensive internship programs encompassing diverse courses, including but not limited to UI/UX, backend development (.NET, C#, Node.js), frontend development, full stack development, mobile software development (Flutter, Dart, React Native), and blockchain technology.
                  </Typography>
                </Box>
              </Grid>
              <Grid item data-aos="fade-up" lg={4} md={6} sm={6}>
                <Box
                  align="center"
                  sx={{
                    bgcolor: "#fffcf8",
                    height: {lg:"400px", md:'400px', xs:'500px'},
                    boxShadow: " 0px 4px 25px rgba(0, 0, 0, 0.08)",
                    borderRadius: "25px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    px: 4,
                    transition:'.2s all linear','&:hover':{ boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'} 
                  }}
                >
                  <img src={badge5} alt="Mentorship" />
                  <Typography
                    sx={{ fontFamily: "outfit", fontWeight: 600, mt: 2 }}
                  >
                    Master Class
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      lineHeight: "29px",
                      mt: 2,
                    }}
                  >
     At NUPAT, our program structure includes a weekly master class tailored to educate interns and students on the fundamental principles, ethics, and intricacies that underpin success in the field of technology. This session brings together developers from various disciplines and areas of expertise within the tech community, fostering a comprehensive understanding of the industry.
                  </Typography>
                </Box>
              </Grid>
              <Grid item data-aos="fade-up" lg={4} md={6} sm={6}>
                <Box
                  align="center"
                  sx={{
                    bgcolor: "#fcfcff",
                    height: "400px",
                    boxShadow: " 0px 4px 25px rgba(0, 0, 0, 0.08)",
                    borderRadius: "25px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    px: 4,
                    transition:'.2s all linear','&:hover':{ boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'} 
                  }}
                >
                  <img src={badge6} alt="Mentorship" />
                  <Typography
                    sx={{ fontFamily: "outfit", fontWeight: 600, mt: 2 }}
                  >
                    Certification
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      lineHeight: "29px",
                      mt: 2,
                    }}
                  >
               Upon successful completion of the program, you will be awarded an industry-recognized certificate that serves as a testament to your exceptional skills and dedication to continual professional growth. This certificate stands as a valuable asset, solidifying your expertise and enhancing your credibility within the industry.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
<Box sx={{margin:'0 auto', width:{lg:'45%', md:'60%', xs:'85%'}, mb:12}}>
    <Box mb={4}>
    <Divider>
              <Typography
                sx={{ fontFamily: "outfit", color: "#123662", fontWeight: 600 }}
              >
               TESTIMONIALS
              </Typography>
            </Divider>
            <Typography
                sx={{ fontFamily: "outfit", color: "#1F1F1F", fontWeight: 200 , textAlign:'center'}}
              >
Testimonials from our esteemed clients: What reason could possibly dissuade you from choosing to collaborate with us?

              </Typography>
    </Box>
    <Testimonial/>
</Box>

<Box sx={{margin:'0 auto', width:'85%', display:'flex',flexDirection:{lg:'row', md:'row', xs:'column'}, columnGap:{lg:5,md:5, xs:0,}, rowGap:{lg:0, md:0, xs:5}, mb:10}}>
<ReactPlayer width="100%" controls={true} url='https://youtu.be/UMI7bEQLdD8' />
<ReactPlayer  width="100%" url='https://youtu.be/WMdQ8wOBlmE' />
</Box>


      <Box sx={{ bgcolor: "#F7F7F7", pb:7 }}>
        <Box sx={{ width: {lg:"85%", md:'85%', xs:'95%'}, margin: "0 auto" }}>
          <Box sx={{ width: {lg:"45%",md:'45%', xs:'80%'}, margin: "0 auto", mb: 3 }}>
            <Divider>
              <Typography
                sx={{ fontFamily: "outfit", color: "#123662", fontWeight: 600 }}
              >
                OUR LEADERSHIP
              </Typography>
            </Divider>
          </Box>
          <Grid container spacing={2} >
            <Grid item lg={3} md={6} sm={6} xs={12} data-aos="zoom-in">
              <Box sx={{ bgcolor: "#fff", transition:'.2s all linear','&:hover':{ boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'} }}>
                <Avatar
                  variant="square"
                  src={lead1}
                  sx={{ width: "100%", height: "350px" }}
                />
                <Box align="center">
                  <Typography
                    sx={{
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 500,
                      mt: 4,
                      color: "#02265C",
                      fontSize: "20px",
                    }}
                  >
                    Nnamdi Ugwu
                  </Typography>
                  <Typography
                    sx={{
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 200,
                      mt: 0.7,
                      color: "#666666",
                      fontSize: "18px",
                    }}
                  >
                    Chief Executive Officer
                  </Typography>
                  <Typography
                    sx={{
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#666666",
                      fontSize: "18px",
                    }}
                  >
                    (CEO)
                  </Typography>
<a href="https://www.linkedin.com/in/nnamdi-ugwu-5a1400147/">
                  <Button
                    sx={{
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 200,
                      mt: 2,
                    }}
                  >
                    <LinkedInIcon sx={{ color: "#2276DD", mr: 1 }} /> Find on
                    LinkedIn →
                  </Button>
                  </a>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12} data-aos="zoom-in">
              <Box sx={{ bgcolor: "#fff", transition:'.2s all linear','&:hover':{ boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'} }}>
                <Avatar
                  variant="square"
                  src={lead2}
                  sx={{ width: "100%", height: "350px" }}
                />
                <Box align="center">
                  <Typography
                    sx={{
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 500,
                      mt: 4,
                      color: "#02265C",
                      fontSize: "20px",
                    }}
                  >
                   Chibuike Ugwu
                  </Typography>
                  <Typography
                    sx={{
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 200,
                      mt: 0.7,
                      color: "#666666",
                      fontSize: "18px",
                    }}
                  >
                Chief Technology Officer

                  </Typography>
                  <Typography
                    sx={{
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#666666",
                      fontSize: "18px",
                    }}
                  >
                   (CTO)
                  </Typography>
                    <a href="https://www.linkedin.com/in/henry-chibuike-ugwu-879711131/">
                  <Button
                    sx={{
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 200,
                      mt: 2,
                    }}
                  >
                    <LinkedInIcon sx={{ color: "#2276DD", mr: 1 }} /> Find on
                    LinkedIn →
                  </Button>
                  </a>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12} data-aos="zoom-in">
              <Box sx={{ bgcolor: "#fff", transition:'.2s all linear','&:hover':{ boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'} }}>
                <Avatar
                  variant="square"
                  src={lead3}
                  sx={{ width: "100%", height: "350px" }}
                />
                <Box align="center">
                  <Typography
                    sx={{
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 500,
                      mt: 4,
                      color: "#02265C",
                      fontSize: "20px",
                    }}
                  >
                Olanrewaju Paul
                  </Typography>
                  <Typography
                    sx={{
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 200,
                      mt: 0.7,
                      color: "#666666",
                      fontSize: "18px",
                    }}
                  >
                   Chief Operating Officer

                  </Typography>
                  <Typography
                    sx={{
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#666666",
                      fontSize: "18px",
                    }}
                  >
                 (COO)
                  </Typography>
                    <a href="https://www.linkedin.com/in/olanrewaju-kehinde-paul-019a42167/">
                  <Button
                    sx={{
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 200,
                      mt: 2,
                    }}
                  >
                    <LinkedInIcon sx={{ color: "#2276DD", mr: 1 }} /> Find on
                    LinkedIn →
                  </Button>
                  </a>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12} data-aos="zoom-in">
              <Box sx={{ bgcolor: "#fff", transition:'.2s all linear','&:hover':{ boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'} }}>
                <Avatar
                  variant="square"
                  src={lead4}
                  sx={{ width: "100%", height: "350px" }}
                />
                <Box align="center">
                  <Typography
                    sx={{
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 500,
                      mt: 4,
                      color: "#02265C",
                      fontSize: "20px",
                    }}
                  >
                   Ogunfunmilade Ibukun
                  </Typography>
                  <Typography
                    sx={{
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 200,
                      mt: 0.7,
                      color: "#666666",
                      fontSize: "18px",
                    }}
                  >
                    Brand Manager
                  </Typography>
                  <Typography
                    sx={{
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 200,
                      color: "#666666",
                      fontSize: "18px",
                    }}
                  >
                    (CBO)
                  </Typography>

                  <Button
                    sx={{
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 200,
                      mt: 2,
                    }}
                  >
                    <LinkedInIcon sx={{ color: "#2276DD", mr: 1 }} /> Find on
                    LinkedIn →
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Faq;
