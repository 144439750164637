import React from 'react'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import img1 from '../../assets/Mansonry/1.JPG'
import img2 from '../../assets/Mansonry/2.JPG'
import img3 from '../../assets/Mansonry/3.JPG'
import img4 from '../../assets/Mansonry/4.JPG'
import img5 from '../../assets/Mansonry/5.JPG'
import img6 from '../../assets/Mansonry/6.JPG'
import img7 from '../../assets/Mansonry/7.JPG'
import img8 from '../../assets/Mansonry/8.JPG'
import img9 from '../../assets/Mansonry/9.jpg'
import img10 from '../../assets/Mansonry/10.jpg'
import img11 from '../../assets/Mansonry/11.jpg'
import img12 from '../../assets/Mansonry/12.jpg'
import img13 from '../../assets/Mansonry/13.jpg'
import img14 from '../../assets/Mansonry/14.jpg'
import img15 from '../../assets/Mansonry/15.jpg'
import img16 from '../../assets/Mansonry/16.jpg'
import img17 from '../../assets/Mansonry/17.jpg'
import img18 from '../../assets/Mansonry/18.jpg'

function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

const Mansonry = () => {
  return (
    <ImageList
    sx={{ width:{lg: "100%", md:'100%', xs:'1000px'}, height: 550,  }}
    variant="quilted"
    cols={4}
    rowHeight={121}
  >
    {itemData.map((item) => (
      <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
        <img
          {...srcset(item.img, 121, item.rows, item.cols)}
          alt={item.title}
          loading="lazy"
        />
      </ImageListItem>
    ))}
  </ImageList>
  )
}

export default Mansonry

const itemData = [
{
    img:img1,
    rows: 2,
    cols: 2,
},
{
    img:img2,
},
{
    img:img3,
},
{
    img:img4,
    cols: 2,
},
{
    img:img5,
    cols: 2,
},
{
    img:img8,
    rows: 2,
    cols: 2,
},
{
    img:img7,
},
{
    img:img6,
},
{
    img:img9,
    rows: 2,
    cols: 2,
},
{
    img:img10,
},
{
    img:img11,
},
{
    img:img12,
    cols: 2,
},
{
    img:img13,
    rows: 2,
    cols: 2,
},
{
    img:img14,
    cols: 2,
},
{
    img:img15,
    rows: 2,
    cols: 2,
},
{
    img:img16,
    rows: 2,
    cols: 2,
},
{
    img:img17,
    rows:2,

},
{
    img:img18,
    rows:2,

},
]