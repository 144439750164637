import React from "react";
import {
  AppBar,
  Grid,
  List,
  ListItem,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";
import "../../App.css";
import Drawers from "./Drawers";

const NAVDATA = [
  {
    titlle: "Home",
    link: "/",
  },
  {
    titlle: "About Us",
    link: "/about-us",
  },
  {
    titlle: "Our Services",
    link: "/services",
  },
  {
    titlle: "Summer Code Camp",
    link: "/summer-code-camp",
  },
];

const dropdownItems = [
  {
    titlle: "Code Camp",
    link: "/code-camp",
  },
  {
    titlle: "UK Cohorts",
    link: "/uk-cohorts",
  },
];

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [color, setColor] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const router = useLocation();
  const dropdownAnchorRef = React.useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const handleDropdownItemClick = () => {
    setDropdownOpen(false); // Close dropdown after selecting an item
  };

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", changeColor);
    return () => window.removeEventListener("scroll", changeColor);
  }, []);

  return (
    <AppBar className={color ? "appbar_bg" : "appbar"}>
      <Toolbar sx={{ margin: "0 auto", width: "90%" }}>
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item lg={4} sx={{ ml: { xs: -4 } }}>
            <Link to="/">
              <img src={logo} width="190px" alt="Nupat Logo" />
            </Link>
          </Grid>
          {isMobile ? (
            <Drawers />
          ) : (
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                justifyContent: "flex-end",
              }}
            >
              <List
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  position: "relative",
                  whiteSpace: "nowrap",
                }}
              >
                {NAVDATA.map((nav, index) => (
                  <Link to={nav.link} key={index}>
                    <ListItem
                      selected={router.pathname === nav.link}
                      sx={{
                        color: "#fff",
                        opacity: 1,
                        fontFamily: "outfit",
                        textTransform: "initial",
                        fontSize: "14px",
                        fontWeight: 600,
                        "&:hover": {
                          color: "#00D8FF",
                        },
                        "&.Mui-selected": {
                          color: "#00d8ff",
                          background: "none",
                        },
                      }}
                    >
                      {nav.titlle}
                    </ListItem>
                  </Link>
                ))}
                <ListItem
                  button
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  onClick={toggleDropdown}
                  ref={dropdownAnchorRef}
                  sx={{
                    color: "#fff",
                    opacity: 1,
                    fontFamily: "outfit",
                    textTransform: "initial",
                    fontSize: "14px",
                    fontWeight: 600,
                    "&:hover": {
                      color: "#00D8FF",
                    },
                    position: "relative",
                  }}
                >
                  <span>Code Camp &#x25BE;</span>
                  {dropdownOpen && (
                    <List
                      id="dropdown-menu"
                      aria-labelledby="dropdown-button"
                      sx={{
                        position: "absolute",
                        background: "#add8e6",
                       
                        zIndex: 1,
                        mt: "5px",
                        p: 0,
                        minWidth: "160px",
                        top: "100%",
                        left: 0,
                        borderRadius: "4px",
                      }}
                    >
                      {dropdownItems.map((item, index) => (
                        <Link
                          to={item.link}
                          key={index}
                          onClick={handleDropdownItemClick}
                          style={{ textDecoration: "none" }}
                        >
                          <ListItem
                            selected={router.pathname === item.link}
                            sx={{
                              color: "green",
                              opacity: 1,
                              fontFamily: "outfit",
                              textTransform: "initial",
                              fontSize: "14px",
                              fontWeight: 600,
                              "&:hover": {
                                color: "#00D8FF",
                              },
                              "&.Mui-selected": {
                                color: "#00d8ff",
                                background: "none",
                              },
                            }}
                          >
                            {item.titlle}
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  )}
                </ListItem>
              </List>
              <a
                href="https://nupatinitiatives.org/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#fff",
                  fontFamily: "outfit",
                  opacity: 1,
                  textTransform: "initial",
                  fontWeight: 200,
                  marginLeft: "12px",
                  fontSize: "14px",
                }}
              >
                Nupat Initiatives
              </a>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
