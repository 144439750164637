import React from 'react'

const ItServicesBenefitsCard = (props) => {
  return (
    <div className='flex flex-col gap-1 px-3 font-["outfit"] justify-center border justify-center rounded-[15px] border-gray-200 md:max-w-[48%] lg:w-[420px]'>
        <div className='mx-auto pt-2'>
            <img src={props.img} />
        </div>
        <div className='mx-auto text-[20px] lg:text-[24px] font-bold text-[#131E47]'>{props.title}</div>
        <div className='pb-7 lg:w-[393px] text-[#333] font-light text-[16px] lg:text-[17px] text-center mx-auto'>{props.paragraph}</div>
    </div>
  )
}

export default ItServicesBenefitsCard