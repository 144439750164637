import * as yup from "yup"


export const basicSchema = yup.object().shape({
  firstName: yup.string().required("Firstname Required"),
  surName: yup.string().required("Surname Required"),
  email: yup.string().email("Please enter a valid email").required("Email Required"),
  phoneNumber: yup.number().required("Phone Number Required"),
  message: yup.string().min(6).required("Message can't be empty")
})

export const HomeSchemaData = [
  {
    "@context": "https://schema.org",
    "@type": "EducationalOrganization",
    "name": "Nupat Technologies",
    "url": "https://www.nupat.africa/",
    "logo": "https://www.nupat.africa/logo.png",
    "description": "Nupat Technologies offers tech education and professional software development services in Lagos, Nigeria, with courses in coding, cybersecurity, data science, product design, and more.",
    "keywords": "Tech academy in Lagos, IT training, Software development, Data science, Coding bootcamp",
    "sameAs": [
      "https://www.facebook.com/nupat_technologies",
      "https://twitter.com/nupat_technologies",
      "https://instagram.com/nupat_technologies",
      "https://www.linkedin.com/company/nupat-technologies"
    ],
    "foundingDate": "2020-01-01",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+2348031958586",
      "contactType": "Customer Service",
      "areaServed": "NG",
      "availableLanguage": "English"
    },
    "location": [
      {
        "@type": "Place",
        "name": "Nupat Technologies - Ikeja Hub",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "54B Adeniyi Jones",
          "addressLocality": "Ikeja",
          "addressRegion": "Lagos",
          "postalCode": "100271",
          "addressCountry": {
            "@type": "Country",
            "name": "NG"
          }
        }
      },
      {
        "@type": "Place",
        "name": "Nupat Technologies - Lekki Hub",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "23 Adebisi Oyenola Street, Peaceville Estate",
          "addressLocality": "Lekki",
          "addressRegion": "Lagos",
          "postalCode": "105102",
          "addressCountry": {
            "@type": "Country",
            "name": "NG"
          }
        }
      },
      {
        "@type": "Place",
        "name": "Nupat Technologies - Yaba Hub",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "46 Community Road, Akoka",
          "addressLocality": "Yaba",
          "addressRegion": "Lagos",
          "postalCode": "101245",
          "addressCountry": {
            "@type": "Country",
            "name": "NG"
          }
        }
      }
    ],
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.7",
      "reviewCount": "256"
    },
    "review": [
      {
        "@type": "Review",
        "datePublished": "2024-11-01",
        "reviewBody": "Fantastic learning experience! The instructors are incredibly supportive and the hands-on projects are invaluable for real-world application.",
        "author": {
          "@type": "Person",
          "name": "Agbaleke Favour"
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5"
        }
      },
      {
        "@type": "Review",
        "datePublished": "2024-11-02",
        "reviewBody": "Nupat Technologies truly raises the standard in tech education, offering courses that cater to current industry needs with an emphasis on practical skills.",
        "author": {
          "@type": "Person",
          "name": "Profitearner Chimdi"
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5"
        }
      },
      {
        "@type": "Review",
        "datePublished": "2024-11-03",
        "reviewBody": "Enrolled in the UI/UX design course, and it was excellent! Detailed and well-organized curriculum with skilled instructors.",
        "author": {
          "@type": "Person",
          "name": "Faith Ifeoma"
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "4"
        }
      },
      {
        "@type": "Review",
        "datePublished": "2024-11-04",
        "reviewBody": "Great place to learn coding and development with a practical approach that really prepares you for the job market.",
        "author": {
          "@type": "Person",
          "name": "Silas Abayomi"
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5"
        }
      },
      {
        "@type": "Review",
        "datePublished": "2024-11-05",
        "reviewBody": "The data science and analytics course was thorough and accessible for beginners. I feel much more confident in my data analysis skills!",
        "author": {
          "@type": "Person",
          "name": "Taiwo Badru"
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5"
        }
      }
    ]
  },
  {
    "@context": "https://schema.org",
    "@type": "Event",
    "name": "November 2024 Cohort - Nupat Code Camp",
    "startDate": "2024-11-01",
    "endDate": "2025-04-30",
    "eventAttendanceMode": "http://schema.org/MixedEventAttendanceMode",
    "description": "Join our intensive 4-6 months tech bootcamp, available both online and onsite.",
    "location": {
      "@type": "Place",
      "name": "Nupat Technologies",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "54B Adeniyi Jones",
        "addressLocality": "Ikeja",
        "addressRegion": "Lagos",
        "postalCode": "100271",
        "addressCountry": {
          "@type": "Country",
          "name": "NG"
        }
      }
    },
    "offers": {
      "@type": "Offer",
      "url": "https://www.nupat.africa/code-camp",
      "priceCurrency": "NGN",
      "price": "200000",
      "availability": "http://schema.org/InStock",
      "validFrom": "2024-11-01"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Frontend Development",
    "description": "Learn HTML, CSS, JavaScript, and frameworks.",
    "courseMode": "Onsite and Online",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Backend/API Development",
    "description": "Master backend technologies and API development.",
    "courseMode": "Onsite and Online",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Data Science and Analytics",
    "description": "Gain expertise in data manipulation and machine learning.",
    "courseMode": "Onsite and Online",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "Software Development",
    "description": "Custom software development solutions for businesses.",
    "serviceType": "Software Consulting",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "Tech Training",
    "description": "Professional tech training programs in Lagos.",
    "serviceType": "Educational Services",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies"
    }
  }
]


export const AboutSchemadata = [
  {
    "@context": "https://schema.org",
    "@type": "EducationalOrganization",
    "name": "Nupat Technologies",
    "url": "https://www.nupat.africa/",
    "logo": "https://www.nupat.africa/logo.png",
    "description": "Nupat Technologies offers tech education and professional software development services in Lagos, Nigeria, with courses in coding, cybersecurity, data science, product design, and more.",
    "keywords": "Tech academy in Lagos, IT training, Software development, Data science, Coding bootcamp",
    "sameAs": [
      "https://www.facebook.com/nupat_technologies",
      "https://twitter.com/nupat_technologies",
      "https://instagram.com/nupat_technologies",
      "https://www.linkedin.com/company/nupat-technologies"
    ],
    "foundingDate": "2020-01-01",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+2348031958586",
      "contactType": "Customer Service",
      "areaServed": "NG",
      "availableLanguage": "English"
    },
    "location": [
      {
        "@type": "Place",
        "name": "Nupat Technologies - Ikeja Hub",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "54B Adeniyi Jones",
          "addressLocality": "Ikeja",
          "addressRegion": "Lagos",
          "postalCode": "100271",
          "addressCountry": {
            "@type": "Country",
            "name": "NG"
          }
        }
      },
      {
        "@type": "Place",
        "name": "Nupat Technologies - Lekki Hub",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "23 Adebisi Oyenola Street, Peaceville Estate",
          "addressLocality": "Lekki",
          "addressRegion": "Lagos",
          "postalCode": "105102",
          "addressCountry": {
            "@type": "Country",
            "name": "NG"
          }
        }
      },
      {
        "@type": "Place",
        "name": "Nupat Technologies - Yaba Hub",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "46 Community Road, Akoka",
          "addressLocality": "Yaba",
          "addressRegion": "Lagos",
          "postalCode": "101245",
          "addressCountry": {
            "@type": "Country",
            "name": "NG"
          }
        }
      }
    ]
  }
]


export const CourseSchemaData = [
  {
    "@context": "https://schema.org",
    "@type": "EducationalOrganization",
    "name": "Nupat Technologies",
    "url": "https://www.nupat.africa/",
    "logo": "https://www.nupat.africa/logo.png",
    "description": "Nupat Technologies offers tech education and professional software development services in Lagos, Nigeria, with courses in coding, cybersecurity, data science, product design, and more.",
    "keywords": "Tech academy in Lagos, IT training, Software development, Data science, Coding bootcamp",
    "sameAs": [
      "https://www.facebook.com/nupat_technologies",
      "https://twitter.com/nupat_technologies",
      "https://instagram.com/nupat_technologies",
      "https://www.linkedin.com/company/nupat-technologies"
    ],
    "foundingDate": "2020-01-01",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+2348031958586",
      "contactType": "Customer Service",
      "areaServed": "NG",
      "availableLanguage": "English"
    },
    "location": [
      {
        "@type": "Place",
        "name": "Nupat Technologies - Ikeja Hub",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "54B Adeniyi Jones",
          "addressLocality": "Ikeja",
          "addressRegion": "Lagos",
          "postalCode": "100271",
          "addressCountry": {
            "@type": "Country",
            "name": "NG"
          }
        }
      },
      {
        "@type": "Place",
        "name": "Nupat Technologies - Lekki Hub",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "23 Adebisi Oyenola Street, Peaceville Estate",
          "addressLocality": "Lekki",
          "addressRegion": "Lagos",
          "postalCode": "105102",
          "addressCountry": {
            "@type": "Country",
            "name": "NG"
          }
        }
      },
      {
        "@type": "Place",
        "name": "Nupat Technologies - Yaba Hub",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "46 Community Road, Akoka",
          "addressLocality": "Yaba",
          "addressRegion": "Lagos",
          "postalCode": "101245",
          "addressCountry": {
            "@type": "Country",
            "name": "NG"
          }
        }
      }
    ],
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.7",
      "reviewCount": "256"
    },
    "review": [
      {
        "@type": "Review",
        "datePublished": "2024-11-01",
        "reviewBody": "Fantastic learning experience! The instructors are incredibly supportive and the hands-on projects are invaluable for real-world application.",
        "author": {
          "@type": "Person",
          "name": "Agbaleke Favour"
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5"
        }
      },
      {
        "@type": "Review",
        "datePublished": "2024-11-02",
        "reviewBody": "Nupat Technologies truly raises the standard in tech education, offering courses that cater to current industry needs with an emphasis on practical skills.",
        "author": {
          "@type": "Person",
          "name": "Profitearner Chimdi"
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5"
        }
      },
      {
        "@type": "Review",
        "datePublished": "2024-11-03",
        "reviewBody": "Enrolled in the UI/UX design course, and it was excellent! Detailed and well-organized curriculum with skilled instructors.",
        "author": {
          "@type": "Person",
          "name": "Faith Ifeoma"
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "4"
        }
      },
      {
        "@type": "Review",
        "datePublished": "2024-11-04",
        "reviewBody": "Great place to learn coding and development with a practical approach that really prepares you for the job market.",
        "author": {
          "@type": "Person",
          "name": "Silas Abayomi"
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5"
        }
      },
      {
        "@type": "Review",
        "datePublished": "2024-11-05",
        "reviewBody": "The data science and analytics course was thorough and accessible for beginners. I feel much more confident in my data analysis skills!",
        "author": {
          "@type": "Person",
          "name": "Taiwo Badru"
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5"
        }
      }
    ]
  },
  {
    "@context": "https://schema.org",
    "@type": "Event",
    "name": "November 2024 Cohort - Nupat Code Camp",
    "startDate": "2024-11-01",
    "endDate": "2025-04-30",
    "eventAttendanceMode": "http://schema.org/MixedEventAttendanceMode",
    "description": "Join our intensive 4-6 months tech bootcamp, available both online and onsite.",
    "location": {
      "@type": "Place",
      "name": "Nupat Technologies",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "54B Adeniyi Jones",
        "addressLocality": "Ikeja",
        "addressRegion": "Lagos",
        "postalCode": "100271",
        "addressCountry": {
          "@type": "Country",
          "name": "NG"
        }
      }
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Fullstack Software Development",
    "description": "Learn to build complete web applications with client and server technologies.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Frontend Software Development",
    "description": "Develop interactive user interfaces and learn essential frontend technologies.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Backend Software Development (Java Track)",
    "description": "Master server-side development and APIs with Java.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Backend Software Development (Node.js Track)",
    "description": "Build efficient server-side applications using JavaScript.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Backend Software Development (.NET)",
    "description": "Build secure and scalable backend applications using .NET technologies.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Mobile Software Development",
    "description": "Learn to create cross-platform mobile applications.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Blockchain Development",
    "description": "Develop decentralized applications and smart contracts.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Data Science & Machine Learning",
    "description": "Dive into data analysis and predictive modeling.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Data Science & Analytics",
    "description": "Master data manipulation, analysis, and visualization for business insights.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Business Analysis",
    "description": "Equip yourself with essential business analysis skills to translate business needs into effective technical solutions.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "UI/UX Design",
    "description": "Learn to create engaging and user-friendly digital experiences.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "DevOps",
    "description": "Learn the tools and processes to automate and streamline software deployment.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Software Testing",
    "description": "Ensure software quality with industry-standard testing methods.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Product Management",
    "description": "Gain skills to manage the full product lifecycle, from ideation to launch.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Project Management",
    "description": "Master project management fundamentals, including Agile, Scrum, and Waterfall methodologies.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Technical Writing",
    "description": "Develop skills to create clear and effective technical documentation.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Digital Marketing",
    "description": "Learn to craft compelling digital marketing strategies that attract, engage, and convert leads using the AIDA framework.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Cyber Security",
    "description": "Learn essential cybersecurity skills to protect systems and data from threats.",
    "educationalCredentialAwarded": "Certificate",
    "provider": {
      "@type": "EducationalOrganization",
      "name": "Nupat Technologies",
      "url": "https://www.nupat.africa/code-camp"
    }
  }
]  