import error from "../assets/error.png";

export const ErrorPage = () => {
  return (
    <div className="errorContainer">
      <div className="errorAlign">
        <div>
          <div className="error">
            <img src={error} />
          </div>
          <div>
            <p className="textCenter">
              The page you're looking for is not available take the moment to
              check our main page out
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                borderBottom: "2px",
              }}
            >
              <a href="https://www.nupat.africa" className="textCenter">
                Go back Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
