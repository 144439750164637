import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Drawer, IconButton, List, ListItem } from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/logo2.png";

const NAVDATA = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About Us",
    link: "/about-us",
  },
  {
    title: "Our Services",
    link: "/services",
  },
  {
    title: "Code Camp",
    link: "/code-camp",
  },
  {
    title: "UK Cohorts",
    link: "/uk-cohorts",
  },
  {
    title: "Summer Code Camp",
    link: "/summer-code-camp",
  },
  {
    title: "IT services",
    link: "/it-services",
  },
];

const Drawers = () => {
  const router = useLocation();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);
  return (
    <>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List
          sx={{
            width: "100vw",
            height: "100vh",
            background:
              "linear-gradient(270.4deg, #131E47 17.31%, #338695 99.58%)",
            overflowY: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link to="/">
              <img src={Logo} alt="Logo" width={100} />
            </Link>
            <IconButton sx={{ mr: 2 }} onClick={() => setOpenDrawer(false)}>
              <CloseIcon sx={{ color: "#fff", fontSize: "30px" }} />
            </IconButton>
          </Box>
          <Box
            align="center"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              mt: 7,
            }}
          >
            {NAVDATA.map((nav) => (
              <Link
                to={nav.link}
                style={{ textDecoration: "none" }}
                onClick={() => setOpenDrawer(false)}
              >
                <ListItem
                  // selected={router.pathname === nav.link}
                  sx={{
                    fontFamily: "outfit",
                    color: "#fff",
                    fontWeight: 400,
                    fontSize: "18px",
                    mb: 3,
                    width: "fit-content",
                    "&.Mui-selected": {
                      color: "#00d8ff",
                      background: "none",
                    },
                  }}
                >
                  {nav.title}
                </ListItem>
              </Link>
            ))}

            <a
              href="https://nupatinitiatives.org/"
              style={{ textDecoration: "none" }}
              onClick={() => setOpenDrawer(false)}
            >
              <ListItem
                sx={{
                  fontFamily: "outfit",
                  color: "#fff",
                  fontWeight: 200,
                  fontSize: "18px",
                  mt: 5,
                  width: "fit-content",
                }}
              >
                Nupat Initiatives
              </ListItem>
            </a>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box className="drawer" sx={{ height: "200px" }}></Box>
        </List>
      </Drawer>
      <IconButton
        sx={{ color: color ? "#000" : "#000", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon sx={{ color: "#fff" }} />
      </IconButton>
    </>
  );
};

export default Drawers;
