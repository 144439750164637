import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import augustine from '../../assets/Augustine.png'
import sammy from '../../assets/sam.jpeg'


const cardData = [
    { title: 'Augustine Aguworumba', description: '“ My time at NUPAT Technologies will always be remarkable because it gave me the opportunity to discover myself. The Hub will always be my first recommendation to anyone who is trying to start out in tech. If you are aspiring, taking the bold step is one thing, and if you can do that, the one good place for you to build your craft, and learn the invaluable skills you require in the journey into the tech world, is NUPAT Technologies”', post:'Android Engineer at League Solutions', avater:augustine },
    { title: 'Alimi Samuel', description: '“As a Front-End Engineer, I want to express my gratitude to Nupat for providing a fantastic learning experience. The curriculum was well-designed, up-to-date, and covered all the essential topics in front-end development. The instructors were knowledgeable and supportive, creating an environment conducive to growth. I feel confident and well-prepared to tackle real-world projects after completing the program at Nupat. Thank you for equipping me with the skills and knowledge to succeed in my career.”',post:'Front End Developer', avater:sammy  },
    { title: 'Blessing Nwokolo', description:'During my time at Nupat Code Camp, I was impressed with the gender-inclusive environment.   As a woman in tech, I often feel out of place, but the bootcamp provided a welcoming atmosphere where everyone was treated equally, and I was able to learn and grow without feeling discriminated against. Additionally, the six-month paid internship program was an excellent opportunity for me to build my professional portfolio and gain practical experience on life project. Another benefit of Nupat Code Camp was the masterclass sessions, where we learned valuable skills. My advice to new members is to make the most of these sessions and take the initiative to Learn as much as possible. With the supportive community at the Nupat Code Camp, you have everything you need to achieve your goals and succeed in the tech industry.', post:'Data Science, and Machine Learning', avater:'NB' },
    { title: 'Ayotayo Badejo', description:'Nupat Code Camp gave me the opportunity to learn valuable IT skills in a supportive and collaborative environment. The task-based methodology helped me to not only understand the theory but also to apply it practically to real-world projects. I also appreciated that despite the unstable power supply in Nigeria, the code camp provided constant electricity, making it possible to maximize our learning and project time. The provision of free Wi-Fi also made it easy for me to access online resources and further develop my skills beyond the bootcamp training. Overall, my experience at Nupat Code Camp was invaluable, and I would highly recommend it to anyone looking to enhance their IT skills.', post:'Full Stack Software Development', avater:'BA' },

    { title: 'Joseph Oluwaseun', description:"I want to update the house on the latest happening on my software development journey. I resumed work yesterday as an Associate Software Engineer. Thanks to Nupat for the training, the amazing people I met here and most especially my instructor, George, You're the best! NB; I haven't left, I'm still a part of the family.", post:'.net Development', avater:'OJ' },
    // Add more cards as needed
  ];

const Card = ({ title, description, post, avater }) => {

    return (
   <Box sx={{bgcolor:'#fff', p:5}}>
<Typography sx={{fontFamily:'outfit', fontSize:'16px', fontWeight:200, textAlign:'center'}}>{description}</Typography>

<Box sx={{width:{lg:'70%', md:'70%', xs:'90%'}, margin:'0 auto', mt:4}} align="center">
    <Grid container>
        <Grid item lg={2} align="right">
            <Avatar src={avater} >
              {avater}
              </Avatar>
       
        </Grid>
        <Grid item lg={8} xs={10}>
            <Typography sx={{fontFamily:'outfit', fontSize:'16px', fontWeight:400, }}>{title}</Typography>
            <Typography sx={{fontFamily:'outfit', fontSize:'12px', fontWeight:200, color:'#888888'}}>{post}</Typography>
        </Grid>
    </Grid>
</Box>
   </Box>
    );
  };
const Testimonial = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
      };
  return (
    <Slider {...settings}>
    {cardData.map((card, index) => (
      <div key={index}>
        <Card title={card.title} description={card.description} post={card.post} avater={card.avater} />
      </div>
    ))}
  </Slider>
  )
}

export default Testimonial