import React from "react";
import Navbar from "../Components/Head/Navbar";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useFormik } from "formik";
import emailjs from "emailjs-com";
import makeStyles from "@mui/styled-engine";
import badge1 from "../assets/icons/Badge.svg";
import badge2 from "../assets/icons/BadgeIT.svg";
import badge3 from "../assets/icons/BadgeNewTrain.svg";
import badge4 from "../assets/icons/BadgeOut.svg";
import badge5 from "../assets/icons/BadgeShips.svg";
import badge6 from "../assets/icons/BadgeTrain.svg";
import Footer from "../Components/Footer/Footer";
import img1 from "../assets/2.JPG";
import { basicSchema } from "../Components/Schemas";
import "../App.css";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  placeholder: {
    "&::placeholder": {
      fontFamily: "outfit", // Replace with your desired font family
    },
  },
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#02265C",
    },
  },
});
const theming = createTheme({
  palette: {
    primary: {
      main: "#2C7185",
    },
  },
});

const OurServices = () => {
  const classes = useStyles();
  const form = useRef();
  console.log(form.current);

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      firstName: "",
      surName: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validationSchema: basicSchema,
    onSubmit: () => {
      sendEmail();
    },
  });
  const sendEmail = (e) => {
    // e.preventDefault();
    emailjs
      .sendForm(
        "service_pty01yr",
        "template_0zavzxf",
        form.current,
        "YL78E6FahYSg7okra"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Thank you for contacting Nupat, you'll be contacted shortly");
          values.firstName = "";
        },
        (error) => {
          console.log(error.text);
          alert(
            "There was an error trying to process your message, refresh your browser and try again!"
          );
        }
      );
    e.target.reset();
    // console.log(values)
  };

  console.log(values);
  console.log(errors);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Our Services | Nupat Technologies </title>
        <link rel="canonical" href="https://nupat.africa/" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-2HLS2GJ4LF"
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-2HLS2GJ4LF');
          `}
        </script>
      </Helmet>
      <Navbar />
      <Box
        className="services"
        sx={{
          height: "300px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",

            fontFamily: "outfit",
            fontWeight: 600,
            color: "#fff",
            fontSize: { lg: "60px", md: "40px", xs: "30px" },
          }}
        >
          Our Services
        </Typography>
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",

            fontFamily: "outfit",
            fontWeight: 200,
            color: "#fff",
            fontSize: { lg: "20px", md: "20px", xs: "16px" },
          }}
        >
          Empowering businesses with cutting-edge technological solutions
        </Typography>
      </Box>
      <Box
        sx={{
          bgcolor:
            " linear-gradient(269.86deg, #B0BBBC -57.34%, #FBFBFB 26.77%, #FBFBFB 72.48%, #B0BBBC 148.66%)",
        }}
      >
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "85%", md: "85%", xs: "90%" },
            pb: 5,
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              mt: 5,
              fontFamily: "outfit",
              fontWeight: 600,
              fontSize: { lg: "40px", md: "40px", xs: "30px" },
            }}
          >
            What we do at NUPAT
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              mt: 2,
              fontFamily: "outfit",
              fontWeight: 200,
              fontSize: "16px",
              width: { lg: "50%", md: "50%", xs: "95%" },
              margin: "0 auto",
            }}
          >
            We are raising world class tech professionals, building problem
            solving innovations and helping you bring your dreams to realities{" "}
          </Typography>
          <ThemeProvider theme={theme}>
            <Grid container spacing={3} sx={{ mt: 4 }}>
              <Grid item lg={4} md={6} sm={6}>
                <Box
                  sx={{
                    border: "1px solid #d7d7d7",
                    borderRadius: "10px",
                    p: 3,
                  }}
                >
                  <img src={badge1} alt="code_camp" />
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 600,
                      fontSize: "18px",
                      my: 2,
                    }}
                  >
                    NUPAT Code Camp
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      mb: 2,
                      fontSize: "16px",
                    }}
                  >
                    Nupat Code camp is an intensive practical training program
                    that is ushering in a new generation of indigenous tech
                    professionals driving innovation and development in the
                    information and technological industry.{" "}
                  </Typography>
                  <Link to="/code-camp">
                    <Button
                      variant="outlined"
                      sx={{ fontFamily: "outfit", textTransform: "initial" }}
                    >
                      Learn More →
                    </Button>
                  </Link>
                </Box>
              </Grid>
              <Grid item lg={4} md={6} sm={6}>
                <Box
                  sx={{
                    border: "1px solid #d7d7d7",
                    borderRadius: "10px",
                    p: 3,
                    height: "295px",
                  }}
                >
                  <img src={badge2} alt="code_camp" />
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 600,
                      fontSize: "18px",
                      my: 2,
                    }}
                  >
                    IT Services
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      mb: 2,
                      fontSize: "16px",
                    }}
                  >
                    We offer technological solutions ranging from building
                    website to building both web and mobile applications for
                    businesses.
                  </Typography>
                  <Box sx={{ flexGrow: 1, mt: 8 }} />
                  <Link to="/it-services">
                    <Button
                      variant="outlined"
                      sx={{
                        fontFamily: "outfit",
                        textTransform: "initial",
                        marginTop: "auto",
                      }}
                    >
                      Learn More →
                    </Button>
                  </Link>
                </Box>
              </Grid>
              <Grid item lg={4} md={6} sm={6}>
                <Box
                  sx={{
                    border: "1px solid #d7d7d7",
                    borderRadius: "10px",
                    p: 3,
                  }}
                >
                  <img src={badge4} alt="code_camp" />
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 600,
                      fontSize: "18px",
                      my: 2,
                    }}
                  >
                    Talent Outsourcing
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      mb: 2,
                      fontSize: "16px",
                    }}
                  >
                    Maximize your business growth and efficiency by harnessing
                    the power of talent outsourcing. Our company specializes in
                    providing top-notch professionals across various industries
                    to augment your workforce and drive success.
                  </Typography>

                  <Button
                    disabled
                    variant="outlined"
                    sx={{ fontFamily: "outfit", textTransform: "initial" }}
                  >
                    Learn More →
                  </Button>
                </Box>
              </Grid>

              <Grid item lg={4} md={6} sm={6}>
                <Box
                  sx={{
                    border: "1px solid #d7d7d7",
                    borderRadius: "10px",
                    p: 3,
                  }}
                >
                  <img src={badge5} alt="code_camp" />
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 600,
                      fontSize: "18px",
                      my: 2,
                    }}
                  >
                    Internships
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      mb: 2,
                      fontSize: "16px",
                    }}
                  >
                    We offer internships in UI/UX, backend development (.NET,
                    C#, Node.js), frontend development, full stack development,
                    mobile software development (Flutter, Dart, React Native),
                    and blockchain technology.
                  </Typography>

                  <Button
                    disabled
                    variant="outlined"
                    sx={{ fontFamily: "outfit", textTransform: "initial" }}
                  >
                    Learn More →
                  </Button>
                </Box>
              </Grid>
              <Grid item lg={4} md={6} sm={6}>
                <Box
                  sx={{
                    border: "1px solid #d7d7d7",
                    borderRadius: "10px",
                    p: 3,
                  }}
                >
                  <img src={badge3} alt="code_camp" />
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 600,
                      fontSize: "18px",
                      my: 2,
                    }}
                  >
                    Corporate Trainings
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      mb: 2,
                      fontSize: "16px",
                    }}
                  >
                    Our corporate training is an intensive practical training
                    program that is ushering in a new generation of indigenous
                    tech professionals who are driving innovation and
                    development in the information and technological industries.
                  </Typography>

                  <Button
                    disabled
                    variant="outlined"
                    sx={{ fontFamily: "outfit", textTransform: "initial" }}
                  >
                    Learn More →
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Box>

        <Box sx={{ bgcolor: "#fff" }} id="contacts">
          <Box
            sx={{
              margin: "0 auto",
              width: { lg: "75%", md: "75%", xs: "90%" },
              py: 5,
            }}
          >
            <form onSubmit={handleSubmit} ref={form}>
              <Grid container spacing={5}>
                <Grid item lg={6} xs={12}>
                  <Box
                    sx={{
                      bgcolor: "#2C7185",
                      p: 4,
                      color: "#fff",
                      height: "180px",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{ fontFamily: "outfit", fontWeight: 600 }}
                      >
                        Get in touch
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontWeight: 200,
                          fontSize: "12px",
                          color: "#BCC3D2",
                        }}
                      >
                        We’ love to hear from you. Our friendly team is always
                        here to chat.
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{ fontFamily: "outfit", fontWeight: 600 }}
                      >
                        Chat to us
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontWeight: 200,
                          fontSize: "14px",
                        }}
                      >
                        Our friendly team is here to help.
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontWeight: 200,
                          fontSize: "12px",
                          color: "#BCC3D2",
                        }}
                      >
                        Mondays - Saturdays from 8am to 5pm.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <ThemeProvider theme={theming}>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <InputLabel
                          sx={{ fontFamily: "outfit", color: " #020E27" }}
                        >
                          First name
                        </InputLabel>
                        <TextField
                          error={errors.firstName}
                          helperText={errors.firstName}
                          id="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          margin="normal"
                          placeholder="First name"
                          InputProps={{
                            style: {
                              fontFamily: "outfit", // Replace with your desired font family
                            },
                          }}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <InputLabel
                          sx={{ fontFamily: "outfit", color: " #020E27" }}
                        >
                          Last name
                        </InputLabel>
                        <TextField
                          fullWidth
                          margin="normal"
                          placeholder="Last name"
                          error={errors.surName}
                          helperText={errors.surName}
                          id="surName"
                          value={values.surName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            style: {
                              fontFamily: "outfit", // Replace with your desired font family
                            },
                          }}
                        />
                      </Grid>
                      <Grid item lg={12} xs={12}>
                        <InputLabel
                          sx={{ fontFamily: "outfit", color: " #020E27" }}
                        >
                          Email
                        </InputLabel>
                        <TextField
                          fullWidth
                          margin="normal"
                          error={errors.email}
                          helperText={errors.email}
                          id="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="you@gamil.com"
                          InputProps={{
                            style: {
                              fontFamily: "outfit", // Replace with your desired font family
                            },
                          }}
                        />
                      </Grid>
                      <Grid item lg={12} xs={12}>
                        <InputLabel
                          sx={{ fontFamily: "outfit", color: " #020E27" }}
                        >
                          Phone Number
                        </InputLabel>
                        <TextField
                          fullWidth
                          margin="normal"
                          type="number"
                          error={errors.phoneNumber}
                          helperText={errors.phoneNumber}
                          id="phoneNumber"
                          value={values.phoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            style: {
                              fontFamily: "outfit", // Replace with your desired font family
                            },
                          }}
                          placeholder="+1 (555) 000-0000"
                        />
                      </Grid>
                      <Grid item lg={12} xs={12}>
                        <InputLabel
                          sx={{ fontFamily: "outfit", color: " #020E27" }}
                        >
                          Message
                        </InputLabel>
                        <TextField
                          fullWidth
                          multiline
                          rows={5}
                          error={errors.message}
                          helperText={errors.message}
                          id="message"
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            style: {
                              fontFamily: "outfit", // Replace with your desired font family
                            },
                          }}
                          margin="normal"
                          placeholder="Leave us a message..."
                        />
                      </Grid>
                      <Grid item lg={12} xs={12}>
                        <Button
                          variant="contained"
                          type="submit"
                          size="large"
                          fullWidth
                          sx={{
                            fontFamily: "outfit",
                            textTransform: "initial",
                          }}
                        >
                          Send Message
                        </Button>
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default OurServices;
