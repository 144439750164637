import React from 'react';
import Navbar from '../../Components/Head/Navbar';
import { Avatar, Box, Button, Grid, Tooltip, Typography, useTheme, useMediaQuery } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import img1 from '../../assets/CodeCamp/1.svg';
import img2 from '../../assets/CodeCamp/2.svg';
import img3 from '../../assets/CodeCamp/3.svg';
import img4 from '../../assets/CodeCamp/4.svg';
import img5 from '../../assets/CodeCamp/5.svg';
import img6 from '../../assets/CodeCamp/6.svg';
import img7 from '../../assets/CodeCamp/7.svg';
import img8 from '../../assets/CodeCamp/8.svg';
import img9 from '../../assets/CodeCamp/9.svg';
import img10 from '../../assets/CodeCamp/10.svg';
import img11 from '../../assets/CodeCamp/11.svg';
import img12 from '../../assets/CodeCamp/12.svg';
import img13 from '../../assets/CodeCamp/13.svg';
import img14 from '../../assets/CodeCamp/14.svg';
import img15 from '../../assets/CodeCamp/technical writing.jpg';
import img16 from '../../assets/CodeCamp/wordpress.webp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import Footer from '../../Components/Footer/Footer';
import { Helmet } from 'react-helmet';
import ReactCountryFlag from 'react-country-flag';
import SummerCamp from '../../assets/SummerCampCode.png';

const courseData = [
  // {
  //   img:img1,
  //   course:'Full Stack Development',
  //   duration:'6 months course',
  //   description:'HTML & CSS, Vanilla JavaScript, React JS, NodeJS, Express Framework',
  // },
  // {
  //   img: img2,
  //   course: "Data Science and Machine Learning",
  //   duration: "4 months course",
  //   description:
  //     "Python,Numpy, Pandas, Sci-kit Learn, Jupyter notebook,Matplotlib, Tensorflow,Data Visualization, deep learning, neural networks, etc.",
  //   link: "https://forms.gle/DJPimA6X6iyHax2M6",
  // },
  {
    img: img3,
    course: 'Web Development',
    // duration: "4 months course",
    description:
      'Building a simple website from scratch Understanding HTML and CSS for markup and styling. Adding interactivity with JavaScript programming language.',
    link: 'https://forms.gle/DJPimA6X6iyHax2M6',
  },
  // {
  //   img: img1,
  //   course: "Full Stack Development",
  //   duration: "5 months course",
  //   description:
  //     "HTML & CSS, Vanilla JavaScript, React JS, NodeJS, Express Framework",
  //     link: "https://forms.gle/DJPimA6X6iyHax2M6",
  // },
  {
    img: img2,
    course: 'Python Programming ',
    // duration: "4 months course",
    description:
      'Fundamentals of coding through interactive games and projects using Python programming language. Developing problem-solving skills through coding challenges.',
    link: 'https://forms.gle/DJPimA6X6iyHax2M6',
  },
  {
    img: img5,
    course: 'Product design (UI/UX)',
    // duration: "4 months course",
    description:
      'Designing, sketching ideas, using colors and layout Using simple apps or websites that help make designs.',
    link: 'https://forms.gle/DJPimA6X6iyHax2M6',
  },
  {
    img: img13,
    course: 'Graphic Designing',
    // duration: "5 months course",
    description:
      'Introduction to basic design principles Hands-on experience using graphic design software Creating digital artwork, posters, and logos',
    link: 'https://forms.gle/DJPimA6X6iyHax2M6',
  },
  {
    img: img7,
    course: 'Data Analysis',
    // duration: "4 months course",
    description:
      'Basic data entry and formatting data Sorting and filtering Simple calculations  Learning data analysis tools ',
    link: 'https://forms.gle/DJPimA6X6iyHax2M6',
  },
  // {
  //   img: img8,
  //   course: "Mobile App Development (Flutter)    ",
  //   duration: "5 months course",
  //   description:
  //     "HTML & CSS, Vanilla JavaScript, React JS, NodeJS, Express Framework",
  //     link: "https://forms.gle/DJPimA6X6iyHax2M6",
  // },
  // {
  //   img: img9,
  //   course: "Software Testing",
  //   duration: "4 months course",
  //   description:
  //     "SDLC,Software Development Methodology, V Model Agile Methodology, API testing, jira,postman,selenium, mobile testing, web testing, appium,automation testing etc. ",
  //     link: "https://forms.gle/DJPimA6X6iyHax2M6",
  // },
  // {
  //   img: img10,
  //   course: "Cyber Security",
  //   duration: "4 months course",
  //   description:
  //     "HTML,CSS, PYTHON,Security Principles,Security operations,Scripting in cybersecurity etc.",
  //     link: "https://forms.gle/DJPimA6X6iyHax2M6",
  // },

  // {
  //   img: img12,
  //   course: "Mobile App Development (React Native)  ",
  //   duration: "4 months course",
  //   description:
  //     "HTML & CSS, Vanilla JavaScript, React JS, NodeJS, Express Framework",
  //     link: "https://forms.gle/DJPimA6X6iyHax2M6",
  // },
  // {
  //   img: img13,
  //   course: "Project Management",
  //   duration: "4 months course",
  //   description:
  //     "Product Development Product, Development Process Product Management Frameworks,Market Analysis ,Scrum, jira etc. ",
  //     link: "https://forms.gle/DJPimA6X6iyHax2M6",
  // },
  // {
  //   img: img15,
  //   course: "Technical Writing",
  //   duration: "4 months course",
  //   description:
  //     "Clear and Concise Writing,Research,Organization and Structure,Audience Analysis,Visual Communication, etc.",
  //     link: "https://forms.gle/DJPimA6X6iyHax2M6",
  // },
  // {
  //   img: img16,
  //   course: "Word Press Development",
  //   duration: "3 months course",
  //   description: "HTML, CSS, Wordpress, Elementor etc.",
  //   link: "https://forms.gle/DJPimA6X6iyHax2M6",
  // },
];



const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: 'linear-gradient(270deg, #00A5EC 0%, #02265C 123.61%)',
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 13,
    fontFamily: 'outfit',
    padding: 13,
  },
}));


const SummerCodeCamp = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> UK Cohorts | Nupat Technologies </title>
        <link rel="canonical" href="https://nupat.africa/" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-2HLS2GJ4LF"
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-2HLS2GJ4LF');
          `}
        </script>
      </Helmet>
      <Navbar />
      <Box
        className="code_camp_uk bg-center bg-no-repeat"
        sx={{ height: { lg: '400px', md: '400px', xs: '400px' } }}
      >
        <Box
          sx={{
            margin: '0 auto',
            width: { lg: '60%', md: '60%', xs: '90%' },
            pt: { lg: 19, md: 19, xs: 14 },
          }}
        >
          <Typography
            sx={{
              color: '#fff',
              fontFamily: 'outfit',
              fontWeight: 600,
              textAlign: 'center',
              fontSize: { lg: '40px', md: '40px', xs: '30px' },
            }}
          >
      
            <span className="block">
              Nupat Summer Code Camp:{' '}
              <span className="block">Unleash Your Child's Creativity!</span>
            </span>
          </Typography>
          <Typography
            sx={{
              color: '#fff',
              fontFamily: 'outfit',
              fontWeight: 200,
              textAlign: 'center',
              fontSize: { lg: '16px', md: '16px', xs: '14px' },
              lineHeight: { lg: '30px', md: '30px', xs: '23px' },
            }}
          >
          </Typography>
        </Box>
      </Box>

      <Box sx={{ margin: '0 auto', width: '85%' }}>
      <Box sx={{ borderLeft: '7px solid #4bbeff', pl: 2, mt: 5 }}>
        <Typography
          sx={{
            fontFamily: 'outfit',
            fontWeight: 500,
            color: '#484848',
            fontSize: '30px',
          }}
        >
          Guaranteed way to start your Tech career
          <span className="block">
            Nupat Summer Code Camp:{' '}
            <span className="block">Unleash Your Child's Creativity!</span>
          </span>
        </Typography>
      </Box>
      <Grid container spacing={2} alignItems="center" sx={{ mt: 3 }}>
        <Grid item xs={12} sm={6} className="flex justify-center order-xs-2 order-sm-1">
          <img src={SummerCamp} alt="Summer Code Camp" className="h-[500px] w-[400px] mt-[20px]" />
        </Grid>
        <Grid item xs={12} sm={6} className="order-xs-1 order-sm-2">
          <Typography
            sx={{
              fontFamily: 'outfit',
              fontWeight: 200,
              color: '#484848',
            }}
          >
            <span className="block">
              Are you looking to spark your child's interest in technology and
              unleash their creativity this summer? Look no further than our
              Summer Kiddies Code Camp! Our camp is designed to introduce children
              aged 6-9 and 10-18 to the exciting world of technology. Here’s what
              your child can expect to learn and experience:
            </span>
          </Typography>
        </Grid>
      </Grid>
    </Box>

      <Box sx={{ margin: '0 auto', width: '80%', my: 10 }}>
        <Grid container spacing={3}>
          {courseData.map((row, index) => (
            <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
              <Box
                sx={{
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Avatar
                  src={row.img}
                  variant="rounded"
                  sx={{ width: '100%', height: '100px' }}
                />
                <Box sx={{ p: 2, height: '170px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'outfit',
                      fontWeight: 500,
                      fontSize: '18px',
                    }}
                  >
                    {row.course}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'outfit',
                      fontWeight: 200,
                      fontSize: '13px',
                      mt: 2,
                    }}
                  >
                    <AccessTimeIcon sx={{ mr: 1, fontSize: '15px' }} />{' '}
                    {row.duration}
                  </Typography>
                  {/* <Typography sx={{fontFamily:'outfit', fontWeight:200, fontSize:'13px', mt:1}}>&#8358; 350,000</Typography> */}

                  <LightTooltip title={row.description} placement="top-start">
                    <Typography
                      sx={{
                        fontFamily: 'outfit',
                        fontWeight: 200,
                        fontSize: '13px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <Inventory2Icon sx={{ mr: 1, fontSize: '15px', mt: 1 }} />{' '}
                      {row.description}
                    </Typography>
                  </LightTooltip>
                </Box>
                <Box
                  sx={{
                    bgcolor: '#F3F8FF',
                    p: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'outfit',
                        fontWeight: 500,
                        fontSize: '11px',
                      }}
                    >
                      Start date:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'outfit',
                        fontWeight: 600,
                        fontSize: '13px',
                      }}
                    >
                      July 8th, 2024
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'outfit',
                        fontWeight: 500,
                        fontSize: '11px',
                      }}
                    >
                      End date:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'outfit',
                        fontWeight: 600,
                        fontSize: '13px',
                      }}
                    >
                      Aug. 16th, 2024
                    </Typography>
                  </Box>
                  <Box>
                    <a href={row.link} target="_blank">
                      <Button
                        sx={{
                          background:
                            'linear-gradient(270deg, #00A5EC 0%, #02265C 123.61%)',
                          color: '#fff',
                          fontFamily: 'outfit',
                          textTransform: 'initial',
                          fontWeight: 200,
                          px: 2,
                        }}
                      >
                        Enroll Now{' '}
                      </Button>
                    </a>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ margin: '0 auto', width: '85%' }}>
          <Box sx={{ borderLeft: '7px solid #4bbeff', pl: 2, mt: 5 }}>
            <Typography
              sx={{
                fontFamily: 'outfit',
                fontWeight: 500,
                color: '#484848',
                fontSize: '30px',
              }}
            >
              <p>Why choose our Code Camp?</p>
            </Typography>
          </Box>
          <div className="flex flex-col gap-[10px] mt-[20px]">
            <p className="">
              <span className="text-[20px]">Experienced Instructors:</span> Our
              instructors are skilled educators with a passion for teaching
              children and technology.
            </p>
            <p>
              <span className="text-[20px] ">Hands-On Learning:</span> Kids
              learn by doing, with plenty of practical exercises and projects.
            </p>
            <p>
              <span className="text-[20px]">Fun and Engaging Activities:</span>{' '}
              Ensuring personalized attention and guidance for every child.
              Beyond learning, we incorporate games, team challenges, and
              creative projects to keep kids excited.
            </p>
            <p className="">Certification at the end of the program.</p>
            <p>Details: Age Group: 6-9 and 10-18 years old</p>
          </div>

          <div className="flex flex-col gap-[20px] mt-[30px]">
            <p>
              How to Register:{'  '}
              <a
                href="https://nupat.africa/summer-code-camp"
                className="text-green-500"
              >
                Visit: https://nupat.africa/summer-code-camp
              </a>
            </p>

            <p>
              Message/Call (WhatsApp):
              <a
                href="https://wa.me/+2348031958586"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-500 pl-[5px]"
              >
                +234 803 195 8586
              </a>
              <span> </span>
              <a
                href="https://wa.me/+447778070953"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-500 px-[5px]"
              >
                +44 7778 070953
              </a>
              for registration and more information.
            </p>

            <p>
              At NUPAT Summer Code Camp, we believe in empowering children with
              essential skills for the digital age while fostering their
              creativity and passion for technology. Join us this summer and
              watch your child learn, create, and grow!
            </p>
          </div>
        </Box>
      </Box>

      <Footer />
    </>
  );
};

export default SummerCodeCamp;
