import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
  Avatar,
  Button,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import React, { useEffect } from "react";
import what2do from "../../../assets/2.JPG";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom'


const theming = createTheme({
  palette: {
    primary: {
      main: "#2454AF",
    },
  },
});
const Features = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("900"));
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, [])
  return (
    <>
      <ThemeProvider theme={theming}>
        <Box className="div" sx={{ height: "" }}>
          <Box
            sx={{
              margin: "0 auto",
              width: { lg: "85%", md: "85%", sm: "85%", xs: "90%" },
              pt: 8,
              pb: 4,
            }}
          >
            <Grid container spacing={2} sx={{ display: "flex", alignItems: "center" }}>
              <Grid item lg={2} md={3}>
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    color: "#05294A",
                    fontSize: "26px",
                    fontWeight: "bolder",
                  }}
                >
                  WHAT WE DO
                </Typography>
              </Grid>
              <Grid item lg={10} md={9}>
                <Divider />
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={2} columnGap={6}>
              <Grid item lg={3.5} md={12}>
                <Box data-aos="zoom-in">
                  <Avatar
                    src={what2do}
                    variant="square"
                    sx={{ width: { lg: "100%", md: '100%', xs: '90vw' }, height: { lg: "700px", md: '300px', xs: "200px" } }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                lg={3.5}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  sx={{
                    borderLeft: "1px solid #05294A1a",
                    transition: "0.5s all linear",
                    pl: 3,
                    "&:hover": {
                      boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 600,
                      color: "#123662",
                      mb: 2,
                    }}
                    data-aos="fade-up"
                  >
                    NUPAT Code Camp
                  </Typography>
                  <Typography
                    data-aos="fade-up"
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      lineHeight: "30px",
                    }}
                  >
                    Nupat Code camp is an intensive practical training program
                    that is ushering in a new generation of indigenous tech
                    professionals driving innovation and development in the
                    information and technological industry. The training
                    utilizes the experience and skill sets of veterans in the
                    industry. Quality practical training and online resources
                    are used in a conducive, team building and challenging
                    environment to inspire and mold the interns to become great
                    tech experts
                  </Typography>

                  <Button sx={{
                    mt: 2,
                    fontFamily: "outfit",
                    textTransform: "initial",
                  }}
                    data-aos="fade-up">
                    <Link to='/code-camp'>
                      Enroll Now →
                    </Link>
                  </Button>
                </Box>
                <Box
                  sx={{
                    borderLeft: "1px solid #05294A1a",
                    transition: "0.5s all linear",
                    pl: 3,
                    mt: 4,
                    "&:hover": {
                      boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
                    },
                  }}
                >
                  <Typography
                    data-aos="fade-up"
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 600,
                      color: "#123662",
                      mb: 2,
                    }}
                  >
                    <a
                      href="https://wa.me/+2347081475750"
                      target="_blank"
                      rel="noopener noreferrer"
                      className=''

                    >IT Services</a>

                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      lineHeight: "30px",
                    }}
                    data-aos="fade-up"
                  >
                    We offer technological solutions ranging from building
                    website to building both web and mobile applications.
                  </Typography>

                  <Button
                    sx={{
                      mt: 2,
                      fontFamily: "outfit",
                      textTransform: "initial",
                    }}
                    data-aos="fade-up"
                  >
                    <a
                      href="https://wa.me/+2347081475750"
                      target="_blank"
                      rel="noopener noreferrer"
                      className=''

                    >
                      Hire Us →
                    </a>
                  </Button>
                </Box>
              </Grid>
              <Grid
                item
                lg={3.5}
                md={5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  sx={{
                    borderLeft: "1px solid #05294A1a",
                    transition: "0.5s all linear",
                    pl: 3,
                    "&:hover": {
                      boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 600,
                      color: "#123662",
                      mb: 2,
                    }}
                    data-aos="right"
                  >
                    Talent Outsourcing
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      lineHeight: "30px",
                    }}
                    data-aos="fade-up"
                  >
                    We provide businesses with access to a range of skilled
                    professionals who can help them meet their technology needs.
                    These services can include software development, web design,
                    cybersecurity, data analysis, and more.
                  </Typography>

                  <Button
                    data-aos="fade-up"
                    sx={{
                      mt: 2,
                      fontFamily: "outfit",
                      textTransform: "initial",
                    }}
                  >
                    <a
                      href="https://wa.me/+2347081475750"
                      target="_blank"
                      rel="noopener noreferrer"
                      className=''

                    >
                      Hire a Tech Talent →{" "}
                    </a>

                  </Button>
                </Box>
                <Box
                  sx={{
                    borderLeft: "1px solid #05294A1a",
                    transition: "0.5s all linear",
                    pl: 3,
                    mt: 4,
                    "&:hover": {
                      boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
                    },
                  }}
                >
                  <Typography
                    data-aos="fade-up"
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 600,
                      color: "#123662",
                      mb: 2,
                    }}
                  >
                    <Link to='/code-camp'>
                      Trainings
                    </Link>

                  </Typography>
                  <Typography
                    data-aos="fade-up"
                    sx={{
                      fontFamily: "outfit",
                      fontWeight: 200,
                      lineHeight: "30px",
                    }}
                  >
                    We provide businesses with access to a range of skilled
                    professionals who can help them meet their technology needs.
                    These services can include software development, web design,
                    cybersecurity, data analysis, and more.
                  </Typography>

                  <Button
                    data-aos="fade-up"
                    sx={{
                      mt: 2,
                      fontFamily: "outfit",
                      textTransform: "initial",
                    }}
                  >
                    <a
                      href="https://wa.me/+2347081475750"
                      target="_blank"
                      rel="noopener noreferrer"
                      className=''

                    >
                      Hire a Tech Talent →
                    </a>

                  </Button>
                </Box>
                <Button
                  variant="outlined"
                  sx={{ fontFamily: "outfit", textTransform: "initial", mt: 1 }}
                >
                  <Link to='/code-camp'>
                    Explore all of our Services →
                  </Link>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* <<<<<<<<<<<<<<QR >>>>>>>>>>></QR> */}
      </ThemeProvider>
    </>
  );
};

export default Features;
