import React from "react";
import { Helmet } from "react-helmet";

// Component
import Banner from "../Components/Head/Banner";
import GetStarted from "../Components/Sections/GetStarted";
import Features from "../Components/Sections/Features";
import Faq from "../Components/Sections/Faq";
import Footer from "../Components/Footer/Footer";
import Testimonial from "../Components/Sections/Testimonial";
import { HomeSchemaData } from "../Components/Schemas";

const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Home | Nupat Technologies </title>
        <link rel="canonical" href="https://nupat.africa/" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-2HLS2GJ4LF"
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-2HLS2GJ4LF');
          `}
        </script>
      </Helmet>

      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(HomeSchemaData)}
        </script>
      </Helmet>

      <Banner />
      <GetStarted />
      <Features />

      <Faq />
      <Footer />
    </>
  );
};

export default Home;
