import React from "react";
import Navbar from "../Components/Head/Navbar";
import Footer from "../Components/Footer/Footer";
import makeStyles from "@mui/styled-engine";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Tooltip,
  InputLabel,
  TextField,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import image1 from "../assets/codescreen.png";
import image2 from "../assets/networkscreen.png";
import image3 from "../assets/cloudscreen.png";
import image4 from "../assets/blockchainscreen.png";
import image5 from "../assets/aiscreen.png";
import image6 from "../assets/cyberscreen.png";
import { basicSchema } from "../Components/Schemas";
import "../App.css";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { useFormik } from "formik";
import emailjs from "emailjs-com";
import ItServicesBenefitsCard from "../Components/ItservicesBenefits/ItServicesBenefitsCard";
import SettingsIcon from "../assets/settingsicon.png";
import SupportIcon from "../assets/support.png";
import EfficiencyIcon from "../assets/efficiency.png";
import GuidanceIcon from "../assets/guidiance.png";
import SecurityIcon from "../assets/security.png";
import SolutionsIcon from "../assets/solutions.png";

const useStyles = makeStyles((theme) => ({
  placeholder: {
    "&::placeholder": {
      fontFamily: "outfit", // Replace with your desired font family
    },
  },
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#02265C",
    },
  },
});
const theming = createTheme({
  palette: {
    primary: {
      main: "#2C7185",
    },
  },
});

const ItServices = () => {
  const classes = useStyles();
  const form = useRef();
  console.log(form.current);

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      firstName: "",
      surName: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validationSchema: basicSchema,
    onSubmit: () => {
      sendEmail();
    },
  });
  const sendEmail = (e) => {
    // e.preventDefault();
    emailjs
      .sendForm(
        "service_pty01yr",
        "template_0zavzxf",
        form.current,
        "YL78E6FahYSg7okra"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Thank you for contacting Nupat, you'll be contacted shortly");
          values.firstName = "";
        },
        (error) => {
          console.log(error.text);
          alert(
            "There was an error trying to process your message, refresh your browser and try again!"
          );
        }
      );
    e.target.reset();
    // console.log(values)
  };

  return (
    <>
      <Navbar />
      <Box
        className="code_camp2"
        sx={{ height: { lg: "auto", md: "auto", xs: "400px" } }}
      >
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "85%", md: "85%", xs: "90%" },
            pt: { lg: 15, md: 15, xs: 14 },
            pb: 4,
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              fontFamily: "outfit",
              fontWeight: 600,
              lineHeight: "45px",
              mb: 2,
              width: "60%",
              fontSize: { lg: "40px", md: "40px", xs: "30px" },
            }}
          >
            Unleashing Innovation: Your Gateway to Next-Gen IT Services
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              fontFamily: "outfit",
              fontWeight: 200,

              fontSize: { lg: "16px", md: "16px", xs: "14px" },
              lineHeight: { lg: "30px", md: "30px", xs: "23px" },
            }}
          >
            Embark on a journey to unlock the transformative potential of
            cutting-edge technology, fostering both seamless operations and
            groundbreaking innovation that propels us into the future. Our
            dedicated team of proficient IT professionals is committed to
            providing avant-garde solutions that fuel growth and success.
            Whether you need support in network infrastructure, cloud computing,
            cybersecurity, data management, software development, or IT
            consulting, rest assured that we have the expertise to meet your
            requirements.
            <br />
            <br />
            Our team of skilled IT professionals is dedicated to delivering
            innovative solutions that drive growth and success. Whether you
            require assistance with network infrastructure, cloud computing,
            cybersecurity, data management, software development, or IT
            consulting, we have you covered.
          </Typography>
          <Button
            variant="outlined"
            sx={{
              border: "1px solid #fff",
              color: "#fff",
              textTransform: "initial",
              fontFamily: "outfit",
              mt: 2,
              "&:hover": { border: "1px solid #ffffff1a" },
            }}
          >
            Request a Quote
          </Button>
        </Box>
      </Box>
      <Box sx={{ margin: "0 auto", width: "85%", pb: 3 }}>
        <Box sx={{ borderLeft: "7px solid #4bbeff", pl: 2, mt: 5 }}>
          <Typography
            sx={{
              fontFamily: "outfit",
              fontWeight: 500,
              color: "#484848",
              fontSize: "30px",
            }}
          >
            Explore our range of IT services below and take your business to new
            heights.
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={4}>
            <Grid item lg={4}>
              <Box>
                <Avatar
                  src={image1}
                  variant="square"
                  sx={{ width: "100%", height: "200px" }}
                />
                <Typography
                  sx={{ fontFamily: "outfit", fontSize: "23px", mt: 1 }}
                >
                  Software Development
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontSize: "16px",
                    fontWeight: 200,
                    my: 2,
                  }}
                >
                  Software development is the cornerstone of innovative IT solutions. It encompasses the entire process of designing, creating, testing, and maintaining software applications. Our expert developers follow a systematic approach, from understanding client requirements to deploying robust solutions Our commitment extends beyond initial development, ensuring continuous updates and maintenance for optimal performance and security. Trust us to propel your business forward through the power of strategic software development
                </Typography>

                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #02265C",
                    color: "#02265C",
                    textTransform: "initial",
                    fontFamily: "outfit",
                    mt: 2,
                    "&:hover": { border: "1px solid #02265C1a" },
                  }}
                >
                  Talk to us about your project.
                </Button>
              </Box>
            </Grid>
            <Grid item lg={4}>
              <Box>
                <Avatar
                  src={image2}
                  variant="square"
                  sx={{ width: "100%", height: "200px" }}
                />
                <Typography
                  sx={{ fontFamily: "outfit", fontSize: "23px", mt: 1 }}
                >
                  Network Security
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontSize: "16px",
                    fontWeight: 200,
                    my: 2,
                  }}
                >
                  Network security is the backbone of a resilient IT infrastructure. It involves the implementation of measures to safeguard your digital assets, ensuring the confidentiality, integrity, and availability of data. Our comprehensive network security solutions encompass robust firewalls, intrusion detection systems, and advanced encryption technologies. We proactively monitor and assess vulnerabilities, providing a secure environment that defends against unauthorized access and cyber threats. Trust us to fortify your networks and protect your valuable data.
                </Typography>

                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #02265C",
                    color: "#02265C",
                    textTransform: "initial",
                    fontFamily: "outfit",
                    mt: 2,
                    "&:hover": { border: "1px solid #02265C1a" },
                  }}
                >
                  Talk to us about your project.
                </Button>
              </Box>
            </Grid>
            <Grid item lg={4}>
              <Box>
                <Avatar
                  src={image3}
                  variant="square"
                  sx={{ width: "100%", height: "200px" }}
                />
                <Typography
                  sx={{ fontFamily: "outfit", fontSize: "23px", mt: 1 }}
                >
                  Cloud Services
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontSize: "16px",
                    fontWeight: 200,
                    my: 2,
                  }}
                >
                  Cloud services involve the delivery of computing resources, such as storage, processing power, and applications, over the internet. Instead of relying on local servers and infrastructure, businesses leverage cloud platforms to access and manage their data and applications remotely. Cloud services offer scalability, and cost-efficiency, allowing organizations to scale their operations based on demand. Whether it's data storage, computing power, or software solutions, cloud services provide a centralized and accessible environment IT management.
                </Typography>

                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #02265C",
                    color: "#02265C",
                    textTransform: "initial",
                    fontFamily: "outfit",
                    mt: 2,
                    "&:hover": { border: "1px solid #02265C1a" },
                  }}
                >
                  Talk to us about your project.
                </Button>
              </Box>
            </Grid>
            <Grid item lg={4}>
              <Box>
                <Avatar
                  src={image4}
                  variant="square"
                  sx={{ width: "100%", height: "200px" }}
                />
                <Typography
                  sx={{ fontFamily: "outfit", fontSize: "23px", mt: 1 }}
                >
                  Blockchain Development
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontSize: "16px",
                    fontWeight: 200,
                    my: 2,
                  }}
                >
                  Shield your digital assets with our comprehensive
                  cybersecurity services. We specialize in fortifying your IT
                  infrastructure against cyber threats, ensuring the
                  confidentiality and integrity of your sensitive data. From
                  robust firewall setups to advanced threat detection systems,
                  our cybersecurity solutions are designed to safeguard your
                  business. Partner with us to stay one step ahead in the
                  ever-evolving landscape of cyber threats, and let our expert
                  team mitigate risks and secure your digital future. 
                </Typography>

                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #02265C",
                    color: "#02265C",
                    textTransform: "initial",
                    fontFamily: "outfit",
                    mt: 2,
                    "&:hover": { border: "1px solid #02265C1a" },
                  }}
                >
                  Talk to us about your project.
                </Button>
              </Box>
            </Grid>
            <Grid item lg={4}>
              <Box>
                <Avatar
                  src={image5}
                  variant="square"
                  sx={{ width: "100%", height: "200px" }}
                />
                <Typography
                  sx={{ fontFamily: "outfit", fontSize: "23px", mt: 1 }}
                >
                  Software Consulting
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontSize: "16px",
                    fontWeight: 200,
                    my: 2,
                  }}
                >
                  Network security is the backbone of a resilient IT infrastructure. It involves the implementation of measures to safeguard your digital assets, ensuring the confidentiality, integrity, and availability of data. Our comprehensive network security solutions encompass robust firewalls, intrusion detection systems, and advanced encryption technologies. We proactively monitor and assess vulnerabilities, providing a secure environment that defends against unauthorized access and cyber threats. 
                </Typography>

                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #02265C",
                    color: "#02265C",
                    textTransform: "initial",
                    fontFamily: "outfit",
                    mt: 2,
                    "&:hover": { border: "1px solid #02265C1a" },
                  }}
                >
                  Talk to us about your project.
                </Button>
              </Box>
            </Grid>

            <Grid item lg={4}>
              <Box>
                <Avatar
                  src={image6}
                  variant="square"
                  sx={{ width: "100%", height: "200px" }}
                />
                <Typography
                  sx={{ fontFamily: "outfit", fontSize: "23px", mt: 1 }}
                >
                  Cybersecurity Services
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontSize: "16px",
                    fontWeight: 200,
                    my: 2,
                  }}
                >
                  Cloud services involve the delivery of computing resources, such as storage, processing power, and applications, over the internet. Instead of relying on local servers and infrastructure, businesses leverage cloud platforms to access and manage their data and applications remotely. Cloud services offer scalability, and cost-efficiency, allowing organizations to scale their operations based on demand. Whether it's data storage, computing power, or software solutions.Trust us with all storage.
                </Typography>

                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #02265C",
                    color: "#02265C",
                    textTransform: "initial",
                    fontFamily: "outfit",
                    mt: 2,
                    "&:hover": { border: "1px solid #02265C1a" },
                  }}
                >
                  Talk to us about your project.
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <div className="my-16 lg:my-28 mx-6">
        <div className='flex justify-center pb-7 text-[#54595F] text-[18px] lg:text-[28px] font-normal font-["outfit"]'>
          Here's how our IT services can benefit your businesses:
        </div>

        <div className="flex-wrap flex item-center gap-6 justify-center">
          <ItServicesBenefitsCard
            img={SettingsIcon}
            title="Streamlined Operations"
            paragraph="We optimize your IT infrastructure, ensuring seamless connectivity and improved workflow, allowing your team to focus on core business activities."
          />
         
            <ItServicesBenefitsCard
              img={SecurityIcon}
              title="Enhanced Security"
              paragraph="Protect your valuable data and sensitive information from cyber threats with our robust cybersecurity measures, proactive monitoring, and regular vulnerability assessments."
            />
          

         
            <ItServicesBenefitsCard
              img={SolutionsIcon}
              title="Scalable Solutions"
              paragraph="Our services are designed to grow with your business. As your needs evolve, we adapt and provide scalable IT solutions that align with your goals and objectives."
            />
          

          <ItServicesBenefitsCard
            img={EfficiencyIcon}
            title="Increased Efficiency"
            paragraph="We leverage the latest technologies and best practices to automate processes, streamline workflows, and eliminate bottlenecks, resulting in improved productivity and cost savings."
          />
        

        
          <ItServicesBenefitsCard
            img={GuidanceIcon}
            title="Expert Guidance"
            paragraph="Our experienced IT consultants offer strategic guidance, helping you make informed decisions about technology investments, system upgrades, and IT roadmaps that align with your business objectives."
          />
        

        
          <ItServicesBenefitsCard
            img={SupportIcon}
            title="Reliable Support"
            paragraph="Count on our responsive IT support team to promptly address any technical issues or challenges you may encounter, minimizing downtime and ensuring uninterrupted operations."
          />
      </div>

      </div>

      <Box sx={{ bgcolor: "#fff", pt: 7 }}>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "85%", md: "85%", xs: "90%" },
            py: 5,
          }}
        >
          <Typography sx={{ fontFamily: "outfit", fontWeight: 200 }}>
            Ready to take your business to the next level? <br />
            Contact us today to discuss your IT needs and find out how our
            services can propel your success.
          </Typography>
        </Box>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "85%", md: "85%", xs: "90%" },
            pb: 5,
            pt: 3,
          }}
        >
          <form onSubmit={handleSubmit} ref={form}>
            <Grid container spacing={5}>
              <Grid item lg={6} xs={12}>
                <Box
                  sx={{
                    bgcolor: "#2C7185",
                    p: 4,
                    color: "#fff",
                    height: "180px",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontFamily: "outfit", fontWeight: 600 }}>
                      Get in touch
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "outfit",
                        fontWeight: 200,
                        fontSize: "12px",
                        color: " #BCC3D2",
                      }}
                    >
                      We’ love to hear from you. Our friendly team is always
                      here to chat.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ fontFamily: "outfit", fontWeight: 600 }}>
                      Chat to us
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "outfit",
                        fontWeight: 200,
                        fontSize: "14px",
                      }}
                    >
                      Our friendly team is here to help.
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "outfit",
                        fontWeight: 200,
                        fontSize: "12px",
                        color: "#BCC3D2",
                      }}
                    >
                      Mondays - Saturdays from 8am to 5pm.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} xs={12}>
                <ThemeProvider theme={theming}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                      <InputLabel
                        sx={{ fontFamily: "outfit", color: " #020E27" }}
                      >
                        First name
                      </InputLabel>
                      <TextField
                        error={errors.firstName}
                        helperText={errors.firstName}
                        id="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        margin="normal"
                        placeholder="First name"
                        InputProps={{
                          style: {
                            fontFamily: "outfit", // Replace with your desired font family
                          },
                        }}
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <InputLabel
                        sx={{ fontFamily: "outfit", color: " #020E27" }}
                      >
                        Last name
                      </InputLabel>
                      <TextField
                        fullWidth
                        margin="normal"
                        placeholder="Last name"
                        error={errors.surName}
                        helperText={errors.surName}
                        id="surName"
                        value={values.surName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          style: {
                            fontFamily: "outfit", // Replace with your desired font family
                          },
                        }}
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <InputLabel
                        sx={{ fontFamily: "outfit", color: " #020E27" }}
                      >
                        Email
                      </InputLabel>
                      <TextField
                        fullWidth
                        margin="normal"
                        error={errors.email}
                        helperText={errors.email}
                        id="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="you@gamil.com"
                        InputProps={{
                          style: {
                            fontFamily: "outfit", // Replace with your desired font family
                          },
                        }}
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <InputLabel
                        sx={{ fontFamily: "outfit", color: " #020E27" }}
                      >
                        Phone Number
                      </InputLabel>
                      <TextField
                        fullWidth
                        margin="normal"
                        type="number"
                        error={errors.phoneNumber}
                        helperText={errors.phoneNumber}
                        id="phoneNumber"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          style: {
                            fontFamily: "outfit", // Replace with your desired font family
                          },
                        }}
                        placeholder="+1 (555) 000-0000"
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <InputLabel
                        sx={{ fontFamily: "outfit", color: " #020E27" }}
                      >
                        Message
                      </InputLabel>
                      <TextField
                        fullWidth
                        multiline
                        rows={5}
                        error={errors.message}
                        helperText={errors.message}
                        id="message"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          style: {
                            fontFamily: "outfit", // Replace with your desired font family
                          },
                        }}
                        margin="normal"
                        placeholder="Leave us a message..."
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Button
                        variant="contained"
                        type="submit"
                        size="large"
                        fullWidth
                        sx={{ fontFamily: "outfit", textTransform: "initial" }}
                      >
                        Send Message
                      </Button>
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default ItServices;

